<template>
  <button
    ref="scrollToTopButtonRef"
    type="button"
    class="button-secondary !bg-opacity-80 !fixed z-10 bottom-4 end-4 !rounded-full !p-3 shadow-md transition duration-150 ease-in-out active:shadow-lg"
    style="display: none"
    @click="scrollToTop"
  >
    <ArrowUpIcon class="size-4" />
  </button>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import debounce from 'lodash/debounce';
import ArrowUpIcon from '../icons/ArrowUpIcon.vue';

const scrollToTopButtonRef = ref<HTMLButtonElement | null>(null);

const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const handleWindowScroll: () => void = debounce((): void => {
  if (scrollToTopButtonRef.value) {
    if (window.scrollY > 50) {
      scrollToTopButtonRef.value.style.display = 'block';
    } else {
      scrollToTopButtonRef.value.style.display = 'none';
    }
  }
}, 100);

onMounted(() => {
  window.addEventListener('scroll', handleWindowScroll);
});
onUnmounted(() => {
  window.removeEventListener('scroll', handleWindowScroll);
});
</script>
