<template>
  <nav class="app-navbar">
    <ProgressBar v-if="appStore.appBarLoadingCount > 0" class="w-full absolute" />
    <div class="app-navbar-inner-container">
      <div class="hamburger-icon-slot">
        <button
          data-drawer-target="offcanvas-sidemenu"
          data-drawer-toggle="offcanvas-sidemenu"
          type="button"
          class="inline-flex items-center text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <HamburgerIcon class="w-10 h-10 text-gray-800 dark:text-icon" />
        </button>
      </div>
      <div class="dh-logo-slot">
        <a href="javascript:void(0);" class="flex ml-3 xl:hidden">
          <img src="../../assets/branch-logo.svg" class="max-h-11 max-w-[87px]" alt="Digital Hands Logo" />
        </a>
      </div>
      <div class="tenant-name-slot">
        <div v-if="showTenantName">
          {{ tenantName }}
        </div>
      </div>
      <div class="user-profile-slot">
        <div class="flex items-center">
          <div class="flex items-center gap-1 xl:gap-3">
            <AppBarNotificationButton />
            <ProfileMenu />
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import ProgressBar from '../ProgressBar.vue';

import HamburgerIcon from '@/components/icons/HamburgerIcon.vue';

import { useAppStore } from '../../stores/app.store';
import AppBarNotificationButton from '../notification/AppBarNotificationButton.vue';
import ProfileMenu from './ProfileMenu.vue';
import { useImpersonationInfo } from '@/hooks/useImpersonationInfo';

withDefaults(
  defineProps<{
    showTenantName?: boolean;
  }>(),
  {
    showTenantName: true,
  },
);

const appStore = useAppStore();
const impersonationInfo = useImpersonationInfo();
const tenantName = impersonationInfo.impersonatedTenant?.tenantName ?? '';
</script>
