import { useAuth0 } from '@auth0/auth0-vue';
import { defineStore } from 'pinia';
import isEmpty from 'lodash/isEmpty';
import { AuthUser } from '../entities/authUser.entity';
import { publishAccessTokenChanged } from '@/utils/event.utils';
import { dashboardPeriodStorage, authUserStorage } from '@/storages/browser.storage';

export const useAuthStore = defineStore('auth-store', () => {
  let authUser: AuthUser;
  let isAuthenticated: boolean = false;

  const { getAccessTokenSilently, logout } = useAuth0();

  // This function is used to clear all browser storages which is required to be cleared on logout
  const clearBrowserStorages = (): void => {
    dashboardPeriodStorage.remove();
    authUserStorage.remove();
  };

  const signout = (): void => {
    authUser = undefined;
    isAuthenticated = false;
    clearBrowserStorages();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const setUser = (user: AuthUser): void => {
    // set new state to store
    authUser = {
      ...authUser,
      ...user,
    };

    if (!isEmpty(authUser)) {
      isAuthenticated = true;
      authUserStorage.set(authUser);
    }
  };

  const setAccessToken = (token: string): void => {
    // Store access token to local storage
    authUser = {
      ...authUser,
      accessToken: token,
    };
    authUserStorage.set(authUser);
  };

  const getAccessToken = (): string => {
    authUser = authUserStorage.get();

    return authUser?.accessToken;
  };

  const getAndSendAccessTokenToServiceWorker = async (): Promise<string> => {
    // Get the access token
    const token = await getAccessTokenSilently();

    // Store the access token
    setAccessToken(token);

    // Send the access token to service worker communicate component
    publishAccessTokenChanged('access-token-changed', token);

    return token;
  };

  const refreshAccessToken = async (signoutOnRefreshFail = false): Promise<string> => {
    try {
      return await getAndSendAccessTokenToServiceWorker();
    } catch (error) {
      if (signoutOnRefreshFail) {
        signout();
        return;
      }
      throw error;
    }
  };

  const getUser = (): AuthUser => {
    if (isEmpty(authUser)) {
      authUser = authUserStorage.get();
      isAuthenticated = !isEmpty(authUser);
    }

    return authUser;
  };

  const hasAuthenticated = (): boolean => {
    return isAuthenticated;
  };

  return {
    getAndSendAccessTokenToServiceWorker,
    hasAuthenticated,
    getAccessToken,
    setAccessToken,
    refreshAccessToken,
    setUser,
    getUser,
    signout,
  };
});
