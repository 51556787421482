export const ROUTER = {
  APP_NAME: 'Digital Hands Customer Portal',
  BASE_URL: '/',
  AUTH_CALLBACK: {
    KEY: 'auth-callback',
    NAME: 'Auth Callback',
    PATH: '/auth/callback',
  },
  AUTH_SIGNUP: {
    KEY: 'auth-signup',
    NAME: 'Sign Up',
    PATH: '/auth/signup',
  },
  AUTH_EMAIL_VERIFY: {
    KEY: 'auth-email-verify',
    NAME: 'Email Verify',
    PATH: '/auth/email-verify',
  },
  AUTH_ACCOUNT_INACTIVATED: {
    KEY: 'auth-account-inactivated',
    NAME: 'Account Inactivated',
    PATH: '/auth/account-inactivated',
  },
  CUSTOMER: {
    HOME_PAGE: {
      KEY: 'home',
      NAME: 'Home',
      TITLE: 'Digital Hands Customer Portal',
      PATH: '/',
    },
    DASHBOARDS_PAGE: {
      KEY: 'dashboards',
      NAME: 'Dashboards',
      PATH: '/dashboards',
    },
    DASHBOARDS_SIEM_PAGE: {
      KEY: 'dashboards-siem',
      NAME: 'CyGuard Cloud SIEM',
      TITLE: 'CyGuard Cloud SIEM',
      PATH: '/dashboards/cyguard-cloud-siem',
    },
    DASHBOARDS_MITRE_PAGE: {
      KEY: 'dashboards-mitre',
      NAME: 'MITRE ATT&CK Coverage',
      TITLE: 'MITRE ATT&CK Coverage',
      PATH: '/dashboards/mitre-dashboard',
    },
    DASHBOARDS_EDR_PAGE: {
      KEY: 'dashboards-edr',
      NAME: 'CyGuard EDR',
      TITLE: 'CyGuard EDR',
      PATH: '/dashboards/cyguard-edr',
    },
    DASHBOARDS_CASE_PAGE: {
      KEY: 'dashboards-case',
      NAME: 'Cases',
      TITLE: 'Cases',
      PATH: '/dashboards/cases',
    },
    FILES_PAGE: {
      KEY: 'files',
      NAME: 'File Center',
      TITLE: 'File Center',
      PATH: '/files',
    },
    ACTIVITIES_PAGE: {
      KEY: 'activities',
      NAME: 'Activities',
      TITLE: 'Service Activities',
      PATH: '/activities',
    },
    MY_LINKS_PAGE: {
      KEY: 'my-links',
      NAME: 'My Links',
      TITLE: 'My Links',
      PATH: '/my-links',
    },
    SERVICE_PAGE: {
      KEY: 'services',
      NAME: 'Services',
      TITLE: 'Services Information',
      PATH: '/services',
    },
    NOTIFICATION_PAGE: {
      KEY: 'notifications',
      NAME: 'Notifications',
      TITLE: 'Notifications',
      PATH: '/notifications',
    },
    CONTACT_US_PAGE: {
      KEY: 'contact-us',
      NAME: 'Contact Us',
      TITLE: 'Contact Us',
      PATH: '/contact-us',
    },
    SECURITY_POSTURE_SURVEY_PAGE: {
      KEY: 'security-posture-survey',
      NAME: 'Security Posture Survey',
      TITLE: 'Security Posture Survey',
      PATH: '/security-posture-survey',
    },
    MY_CASES_PAGE: {
      KEY: 'my-cases',
      NAME: 'My Cases',
      TITLE: 'My Cases',
      PATH: '/my-cases',
    },
    SERVICENOW_DETAIL_PAGE: {
      KEY: 'servicenow',
      NAME: 'ServiceNow',
      TITLE: 'Case',
      PATH: '/my-cases/:id',
    },
    USER_MANAGEMENT_PAGE: {
      KEY: 'user-management',
      NAME: 'User Management',
      TITLE: 'User Management',
      PATH: '/user-management',
    },
  },
  ADMIN: {
    HOME_PAGE: {
      KEY: 'admin-home',
      NAME: 'Home',
      TITLE: 'Digital Hands Admin Portal',
      PATH: '/admin',
    },
    TENANTS_PAGE: {
      KEY: 'admin-tenants',
      NAME: 'Tenants',
      TITLE: 'Tenant Management',
      PATH: '/admin/tenants',
    },
    FILES_PAGE: {
      KEY: 'admin-files',
      NAME: 'File Center',
      TITLE: 'File Center',
      PATH: '/admin/files',
    },
    CSM_PAGE: {
      KEY: 'admin-csm',
      NAME: 'Internal Admin',
      TITLE: 'Internal User Administration',
      PATH: '/admin/csm',
    },
    CSM_MANAGED_CUSTOMERS_PAGE: {
      KEY: 'admin-csm-managed-customers',
      NAME: "CSM's Managed Customers",
      TITLE: "CSM's Managed Customers",
      PATH: '/admin/csm/:id',
    },
  },
  ADMIN_TENANT: {
    HOME_PAGE: {
      KEY: 'admin-tenant-home',
      TITLE: 'Tenant Admin',
      DYNAMIC_TITLE: '{tenantName}',
      NAME: 'Tenant Home',
      PATH: '/admin/tenants/:tenantId',
    },
    LOGS_PAGE: {
      KEY: 'admin-tenant-logs',
      TITLE: 'Audit Logs - Tenant Admin',
      DYNAMIC_TITLE: 'Audit Logs - {tenantName}',
      NAME: 'Audit Logs',
      PATH: '/admin/tenants/:tenantId/logs',
    },
    FILES_PAGE: {
      KEY: 'admin-tenant-files',
      TITLE: 'File Center - Tenant Admin',
      DYNAMIC_TITLE: 'File Center - {tenantName}',
      NAME: 'File Center',
      PATH: '/admin/tenants/:tenantId/files',
    },
    SECURITY_POSTURE_PAGE: {
      KEY: 'admin-tenant-security-posture',
      TITLE: 'Security Posture - Tenant Admin',
      DYNAMIC_TITLE: 'Security Posture - {tenantName}',
      NAME: 'Security Posture',
      PATH: '/admin/tenants/:tenantId/security-posture',
    },
  },
};
