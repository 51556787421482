import { format, parse } from 'date-fns';
import floor from 'lodash/floor';

export const MIN_DATE = new Date(-8640000000000000);

export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && date && !isNaN(date.getTime());
};

export const fromISODate = (isoDate: string, fallback?: Date): Date => {
  if (!isoDate) {
    return fallback;
  }
  try {
    const date = new Date(isoDate);
    if (!isValidDate(date)) {
      return fallback;
    }
    return date;
  } catch (e) {
    return fallback;
  }
};

export const fromFormattedString = (dateTimeString: string, formatString: string = 'MM-dd-yyyy HH:mm:ss'): Date => {
  return parse(dateTimeString, formatString, new Date());
};

export const formatShortDate = (date: Date, fallback = '-'): string => {
  if (!isValidDate(date)) {
    return fallback;
  }
  return format(date, 'MM-dd-yyyy');
};

export const formatShortDateTime = (date: Date, fallback = '-'): string => {
  if (!isValidDate(date)) {
    return fallback;
  }
  return format(date, 'MM-dd-yyyy hh:mm a');
};

export const formatMonthYear = (date: Date, fallback = '-'): string => {
  if (!isValidDate(date)) {
    return fallback;
  }
  return format(date, 'MM-yyyy');
};

export const formatShortMonthName = (date: Date, fallback = '-'): string => {
  if (!isValidDate(date)) {
    return fallback;
  }
  return format(date, 'MMM');
};

export const formatShortDateFromISODate = (isoDate: string): string => {
  return formatShortDate(fromISODate(isoDate));
};

export function convertMinutesToHHMM(minutes: number): string {
  if (isNaN(minutes)) {
    return '0m';
  }
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = hours === 0 ? '' : `${hours}h`;
  const formattedMins = `${Math.floor(mins)}m`;
  return `${formattedHours} ${formattedMins}`;
}

export function formatRelativeDate(date: Date, includeTime = true): string {
  if (!isValidDate(date)) {
    return '-';
  }
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  if (date.toDateString() === today.toDateString()) {
    return includeTime ? `Today ${format(date, 'h:mm a')}` : 'Today';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return includeTime ? `Yesterday ${format(date, 'h:mm a')}` : 'Yesterday';
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return includeTime ? `Tomorrow ${format(date, 'h:mm a')}` : 'Tomorrow';
  } else {
    return format(date, 'MM-dd-yyyy h:mm a');
  }
}

export function formatRelativePeriod(date: Date): string {
  if (!isValidDate(date)) {
    return '-';
  }
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(diff / 3600000);
  const days = Math.floor(diff / 86400000);

  if (minutes < 1) {
    return 'Just now';
  }
  if (hours < 1) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  }
  if (days < 1) {
    return `${hours} hr${hours > 1 ? 's' : ''} ago`;
  }

  return formatRelativeDate(date);
}

export function groupSectionFromIsoDate(date: Date): { name: string; dateTime: string } {
  let sectionName = '';
  let dateTime = '';

  const dateInMilliseconds = date.getTime();
  const now = Date.now();

  const _hourInMilliseconds = 60 * 60 * 1000;
  const _24HoursInMilliseconds = 24 * _hourInMilliseconds;
  const _1WeekInMilliseconds = 7 * _24HoursInMilliseconds;

  const withinOneDayInMilliseconds = now - _24HoursInMilliseconds;
  const withinOneWeekInMilliseconds = now - _1WeekInMilliseconds;

  if (dateInMilliseconds >= withinOneDayInMilliseconds) {
    const dateHours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    const dateMinutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();

    sectionName = 'Today';
    dateTime = `${dateHours}:${dateMinutes}`;
  } else if (dateInMilliseconds < withinOneDayInMilliseconds && dateInMilliseconds >= withinOneWeekInMilliseconds) {
    const roundedDayDifference = floor((now - dateInMilliseconds) / _24HoursInMilliseconds);
    const splittedDate = formatShortDate(date);
    const dayDifference = roundedDayDifference > 1 ? `${splittedDate}` : 'Yesterday';
    const dateHours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
    const dateMinutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();

    sectionName = 'Recent';
    dateTime = `${dayDifference}, ${dateHours}:${dateMinutes}`;
  } else {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const splittedDate = formatShortDate(date);
    const month = date.getMonth();

    sectionName = months[month];
    dateTime = `${splittedDate}`;
  }

  return {
    name: sectionName,
    dateTime,
  };
}

export function convertISODateToDate(isoDate: string): Date {
  if (!isoDate) return null;

  const split = isoDate.split('T');

  const year = parseInt(split[0].split('-')[0]);
  const month = parseInt(split[0].split('-')[1]);
  const date = parseInt(split[0].split('-')[2]);

  const hour = parseInt(split[1].split(':')[0]);
  const minute = parseInt(split[1].split(':')[1]);
  const second = parseInt(split[1].split(':')[2]);

  const converted = new Date();
  converted.setFullYear(year, month - 1, date);
  converted.setHours(hour, minute, second);

  return converted;
}
