import { buildQueryString, fetchJsonWithAuthorization } from '../../utils/fetch.utils';
import { CUSTOMER_SERVICE_URL } from '../../app-constants/env.constant';
import { Log, Logs, LogsScheme, ImpersonateAction } from '@/entities/log.entity';
import { toCamelCase } from '@/utils/object.utils';
import { objectToEntity } from '@/utils/transform.utils';
import { PaginationDto, PaginationInfo, PaginationInfoSchema } from '@/entities/paginationInfo.entity';

const ROOT_URL = `${CUSTOMER_SERVICE_URL}/api`;

function convertData(items: Logs): Logs {
  return items.map((item: Log) => {
    const arrData = item.message.split('|');
    const parsedMessage = arrData.reduce((acc, curr, idx) => {
      if (idx === 0) return acc;
      const firstColonIndex = curr.indexOf(':');
      if (firstColonIndex === -1) return acc;
      const key = curr.slice(0, firstColonIndex).trim().toLowerCase();
      const value = curr.slice(firstColonIndex + 1).trim();
      acc[key.trim().toLowerCase()] = value.trim();
      return acc;
    }, {});
    item.parsedMessage = parsedMessage;
    return item;
  });
}

export const getLogDetail = async (
  tenantId: string,
  lookback: number,
  { page, limit, sortOrder }: PaginationDto,
): Promise<[Logs, PaginationInfo]> => {
  const query = buildQueryString({
    lookback,
    customer_id: tenantId,
    page: page.toString(),
    page_size: limit.toString(),
    // The Facade API only support for sorting by created_at
    // => do not need to pass sortBy
    sort_order: sortOrder,
  });
  const url = `${ROOT_URL}/admin/audit-logs?${query}`;
  const jsonObj: { items: [] } = await fetchJsonWithAuthorization(url);
  const { items, ...pagination } = jsonObj;
  const convertedData = convertData(toCamelCase(items));
  const logs = objectToEntity<Logs>(LogsScheme, convertedData);
  const paginationInfo = objectToEntity<PaginationInfo>(PaginationInfoSchema, pagination);
  return [logs, paginationInfo];
};

export const impersonationEventLog = async (action: ImpersonateAction, impersonatedUserId: string): Promise<void> => {
  const url = `${ROOT_URL}/admin/audit-logs/impersonation`;
  const options = {
    method: 'POST',
    body: JSON.stringify({ action: action, impersonated_user_id: impersonatedUserId }),
  };
  await fetchJsonWithAuthorization(url, options);
};
