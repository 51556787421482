import { z } from 'zod';
import { PREDEFINED_ROLES } from '../app-constants/role.constant';

export const RoleSchema = z.object({
  id: z.string(),
  roleKey: z.number(),
  roleName: z.string(),
  roleDescription: z.string().optional(),
});

export type Role = z.infer<typeof RoleSchema>;

export type PredefinedRoleKey = keyof typeof PREDEFINED_ROLES;
