import { z } from 'zod';
import { fromISODate } from '../utils/date.utils';

export enum NotificationType {
  contactUs = 'contact_us',
  fileUpload = 'file_upload',
}

export const NotificationSchema = z.object({
  id: z.string(),
  sid: z.number(),
  toCustomerId: z.string().optional(),
  toRoleId: z.string().optional().nullable(),
  fromCustomerId: z.string().optional().nullable(),
  fromUserId: z.string().optional().nullable(),
  createdAt: z
    .string()
    .optional()
    .transform((val) => fromISODate(val) || null),
  subject: z.string().optional(),
  message: z.string().optional(),
  source: z.string().optional(),
  status: z.string().optional(),
  completedAt: z
    .string()
    .optional()
    .transform((val) => fromISODate(val) || null),
  method: z.string().optional(),
  notificationLogId: z.string().optional().nullable(),
  userId: z.string().optional().nullable(),
  isRead: z.boolean().optional().default(false),
});

export const NotificationsSchema = z.array(NotificationSchema);

export type Notification = z.infer<typeof NotificationSchema>;
export type Notifications = z.infer<typeof NotificationsSchema>;
