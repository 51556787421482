<template>
  <component :is="layout"> </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { LAYOUTS } from '../../app-constants/layout.constant';

const route = useRoute();

/**
 *  This is a computed property that will return the name
 *  of the current route
 */
const layout = computed(() => {
  const layoutName = route.meta.layout as string;
  const layout = LAYOUTS[layoutName];

  if (layout) {
    return layout.component;
  }
  return 'div';
});
</script>
