import { z } from 'zod';

export enum ImpersonateAction {
  impersonation_start = 'Impersonation Start',
  impersonation_end = 'Impersonation End',
}

export const LogMessageScheme = z.object({
  user: z.string().optional(),
  email: z.string().optional(),
  file: z.string().optional(),
  size: z.string().optional(),
  status: z.string().optional(),
  action: z.string().optional(),
  failureReason: z.string().optional(),
  caseId: z.string().optional(),
  caseAction: z.string().optional(),
  caseActionTechnology: z.string().optional(),
  caseActionTargets: z.string().optional(),
  impersonatedUser: z.string().optional(),
  impersonatedTenantId: z.string().optional(),
  impersonatedTenant: z.string().optional(),
});

export const LogScheme = z.object({
  occurredAt: z.string(),
  userId: z.string(),
  customerId: z.string(),
  action: z.string(),
  resource: z.string(),
  resourceId: z.string(),
  ipAddress: z.string(),
  message: z.string(),
  parsedMessage: LogMessageScheme.optional(),
  id: z.string(),
  sid: z.number(),
});

export const LogsScheme = z.array(LogScheme);

export type Log = z.infer<typeof LogScheme>;
export type Logs = z.infer<typeof LogsScheme>;
