<template>
  <div class="relative">
    <button :id="`${idRef}-button`" type="button" class="app-navbar-user-profile-button p-2">
      <BellIcon class="w-8 h-8" />
    </button>
    <div v-if="unreadCount > 0" class="absolute w-3.5 h-3.5 bg-red-500 rounded-full top-1 end-1.5"></div>
  </div>
  <div
    :id="idRef"
    class="hidden z-20 flex flex-col w-full sm:w-[70vw] xl:w-[40vw] h-[92dvh] sm:h-[94dvh] xl:h-[91dvh] max-[500px] px-2 pt-3"
  >
    <div class="dropdown-menu grow flex flex-col list-divider overflow-auto">
      <div class="flex gap-4 px-4 py-2">
        <h3 class="grow text-lg sm:text-xl font-semibold text-text">Notifications</h3>
        <button @click="handleClose"><CloseIcon class="size-8 text-text" /></button>
      </div>
      <NotificationList
        class="grow px-4 py-3"
        :items="data?.notificationGroups"
        :total-items="data?.paginationInfo?.totalRecords ?? 0"
        :total-pages="data?.paginationInfo?.totalPages ?? 0"
        :style="{
          // Donot hide the notification list from the rendering since it will cause the virtual list to not render
          // Instead, hide the notification list from the view
          display: hasNotifications ? 'block' : 'none',
        }"
        @mark-all-as-read="handleMarkAllAsRead"
      />
      <div v-if="!hasNotifications" class="flex flex-col items-center justify-between gap-14 py-4">
        <EmptyNotificationIllustration class="mt-14" />
        <p class="text-sm sm:text-base font-semibold text-text-support">There is no notification</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useMutableQuery } from '@/hooks/useMutableQuery';
import BellIcon from '../icons/BellIcon.vue';
import { getNotifications } from '@/services/notification.service';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { Notification } from '@/entities/notification.entity';
import { Dropdown, DropdownInterface, DropdownOptions } from 'flowbite';
import { nanoid } from 'nanoid';
import CloseIcon from '../icons/CloseIcon.vue';
import NotificationList from './NotificationList.vue';
import EmptyNotificationIllustration from '../icons/EmptyNotificationIllustration.vue';

const props = withDefaults(
  defineProps<{
    id?: string;
    dropdownOptions?: DropdownOptions;
  }>(),
  {
    id: undefined,
    dropdownOptions: (): DropdownOptions => ({
      placement: 'bottom',
    }),
  },
);

const idRef = ref<string>();
const dropdownRef = ref<DropdownInterface | null>(null);

onBeforeMount(() => {
  idRef.value = props.id ?? nanoid();
});

onMounted(() => {
  // get the dropdown menu element
  const $targetEl: HTMLElement = document.getElementById(idRef.value);
  // get the element that trigger the dropdown menu on click
  const $triggerEl: HTMLElement = document.getElementById(`${idRef.value}-button`);
  dropdownRef.value = new Dropdown($targetEl, $triggerEl, props.dropdownOptions, {
    id: idRef.value,
  });
});

const handleClose = (): void => {
  dropdownRef.value?.hide();
};

const handleMarkAllAsRead = (): void => {
  mutate();
};

const [{ data }, { mutate }] = useMutableQuery({
  queryKey: ['getNotifications'],
  queryFn: async () => {
    const [notifications, paginationInfo] =
      (await getNotifications({
        page: 1,
        order: 'desc',
      })) ?? [];
    const notificationGroups = notifications.reduce(
      (
        acc: {
          read: Notification[];
          unread: Notification[];
        },
        notification,
      ) => {
        if (notification.isRead) {
          acc.read.push(notification);
        } else {
          acc.unread.push(notification);
        }
        return acc;
      },
      {
        read: [],
        unread: [],
      },
    );

    return { notificationGroups, paginationInfo };
  },
  mutationFn: async () => {},
});

const unreadCount = computed(() => data.value?.notificationGroups?.unread?.length ?? 0);
const hasNotifications = computed(
  () => data.value?.notificationGroups?.unread?.length > 0 || data.value?.notificationGroups?.read?.length > 0,
);
</script>
