import { z } from 'zod';
import { fromISODate } from '@/utils/date.utils';

import { SecurityPostureScoreSchema } from './securityPosture.entity';

export const TenantSchema = z.object({
  id: z.string().optional(),
  tenantName: z.string(),
  tenantAbbreviation: z.string(),
  isSecurityPostureSurveyActivated: z.boolean().optional(),
  isSecurityPostureSurveyCompleted: z.boolean().optional(),
  snowAccountId: z.string(),
  securityPostureScore: SecurityPostureScoreSchema.optional().nullable(),
  createdAt: z
    .string()
    .optional()
    .transform((val: string) => fromISODate(val)),
  modifiedAt: z
    .string()
    .optional()
    .transform((val: string) => fromISODate(val)),
});

export const TenantsSchema = z.array(TenantSchema);

export type Tenant = z.infer<typeof TenantSchema>;
export type Tenants = z.infer<typeof TenantsSchema>;
