<template>
  <aside id="offcanvas-sidemenu" class="app-sidebar -translate-x-full xl:translate-x-0" aria-label="Sidebar">
    <div id="logo-cont" class="app-sidebar-logo-container">
      <RouterLink :to="ROUTER.CUSTOMER.HOME_PAGE.PATH">
        <a href="javascript:void(0);" data-drawer-hide="offcanvas-sidemenu">
          <img src="../../assets/branch-logo.svg" class="app-sidebar-logo" alt="Digital Hands Logo" />
        </a>
      </RouterLink>
    </div>
    <div id="menu-cont" class="app-sidebar-menu-container">
      <SideMenu side-menu-id="offcanvas-sidemenu" :items="menuItems" @on-item-click="handleItemClick" />
    </div>
    <div
      id="cta-support-cont"
      class="flex absolute bottom-0 left-0 z-20 justify-center px-5 pt-3 pb-6 space-x-4 w-full dark:bg-container"
    >
      <div id="dropdown-cta" class="w-full rounded-lg" role="alert">
        <div class="flex mb-3 text-sm text-blue-800 dark:text-text">
          <LifeSaverIcon class="app-sidebar-menu-list-icon" />
          <span class="inline-flex items-center ml-2">Need support?</span>
          <div class="clear-both"></div>
        </div>
        <div class="text-center text-xs font-medium">
          <RouterLink :to="ROUTER.CUSTOMER.CONTACT_US_PAGE.PATH">
            <a
              class="inline-flex items-center justify-center w-full py-2 px-5 text-sm font-medium focus:outline-none bg-green-700 rounded-lg border border-green-700"
              href="javascript:void(0);"
              data-drawer-hide="offcanvas-sidemenu"
            >
              <PhoneIcon class="app-sidebar-menu-list-icon !w-3 !h-3 mr-2" :viewBox="`0 0 12 12`" />
              <span>Contact us</span>
            </a>
          </RouterLink>
        </div>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { markRaw, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { initFlowbite } from 'flowbite';

import HomeIcon from '@/components/icons/HomeIcon.vue';
import ColumnIcon from '@/components/icons/ColumnIcon.vue';
import FileIcon from '@/components/icons/FileIcon.vue';
import InboxIcon from '@/components/icons/InboxIcon.vue';
import LinkIcon from '@/components/icons/LinkIcon.vue';
import GridIcon from '@/components/icons/GridIcon.vue';
import PhoneIcon from '@/components/icons/PhoneIcon.vue';
import LifeSaverIcon from '@/components/icons/LifeSaverIcon.vue';
import TicketIcon from '@/components/icons/TicketIcon.vue';
import UsersIcon from '@/components/icons/UsersIcon.vue';

import { useAccountInformation } from '@/hooks/useAccountInformation';
import { useImpersonationInfo } from '@/hooks/useImpersonationInfo';

import { ROUTER } from '../../app-constants/router.constant';
import { SideMenuItem } from '@/entities/sideMenuItem.entity';
import SideMenu from './SideMenu.vue';
import { checkUserHasRoles } from '@/utils/user.utils';
import { PREDEFINED_ROLES } from '@/app-constants/role.constant';

const impersonationInfo = useImpersonationInfo();
const { products, hasProductChronicle, hasProductSentinelOne, hasProductServiceNow } = useAccountInformation();
const authUser = impersonationInfo.impersonatedUser;
const isCustomerUser = checkUserHasRoles(authUser, [
  PREDEFINED_ROLES.CUSTOMER.id,
  PREDEFINED_ROLES.CUSTOMER_SUCCESS_MANAGER.id,
]);

const menuItems = ref<SideMenuItem[]>([]);

const handleItemClick = (item: SideMenuItem): void => {
  const itemFromList = menuItems.value.find((menuItem) => menuItem.id === item.id);
  itemFromList?.children?.length && (itemFromList.expanded = !itemFromList.expanded);
};

function resizeSideMenu(): void {
  // TODO: Should be refactored to use refs
  const offCanvasSideMenu = document.getElementById('offcanvas-sidemenu');
  const logoView = document.getElementById('logo-cont');
  const menuView = document.getElementById('menu-cont');
  const suppotCTAView = document.getElementById('cta-support-cont');
  const logoMargin = 24;
  if (!offCanvasSideMenu || !logoView || !menuView || !suppotCTAView) return;
  menuView.style.height =
    offCanvasSideMenu.offsetHeight - (logoView.offsetHeight + logoMargin + suppotCTAView.offsetHeight) + 'px';
}

const buildSideMenuItems = (): void => {
  // Homepage
  menuItems.value.push({
    id: ROUTER.CUSTOMER.HOME_PAGE.KEY,
    name: ROUTER.CUSTOMER.HOME_PAGE.NAME,
    link: ROUTER.CUSTOMER.HOME_PAGE.PATH,
    icon: markRaw(HomeIcon),
  });
  // My cases
  menuItems.value.push({
    id: ROUTER.CUSTOMER.MY_CASES_PAGE.KEY,
    name: ROUTER.CUSTOMER.MY_CASES_PAGE.NAME,
    link: ROUTER.CUSTOMER.MY_CASES_PAGE.PATH,
    icon: markRaw(TicketIcon),
  });
  // Dashboard
  menuItems.value.push({
    id: ROUTER.CUSTOMER.DASHBOARDS_PAGE.KEY,
    name: ROUTER.CUSTOMER.DASHBOARDS_PAGE.NAME,
    link: '',
    icon: markRaw(ColumnIcon),
    children: [],
  });
  // File Center
  menuItems.value.push({
    id: ROUTER.CUSTOMER.FILES_PAGE.KEY,
    name: ROUTER.CUSTOMER.FILES_PAGE.NAME,
    link: ROUTER.CUSTOMER.FILES_PAGE.PATH,
    icon: markRaw(FileIcon),
  });
  // Activities
  menuItems.value.push({
    id: ROUTER.CUSTOMER.ACTIVITIES_PAGE.KEY,
    name: ROUTER.CUSTOMER.ACTIVITIES_PAGE.NAME,
    link: ROUTER.CUSTOMER.ACTIVITIES_PAGE.PATH,
    icon: markRaw(InboxIcon),
  });
  // Services
  menuItems.value.push({
    id: ROUTER.CUSTOMER.SERVICE_PAGE.KEY,
    name: ROUTER.CUSTOMER.SERVICE_PAGE.NAME,
    link: ROUTER.CUSTOMER.SERVICE_PAGE.PATH,
    icon: markRaw(GridIcon),
  });
  // My links
  menuItems.value.push({
    id: ROUTER.CUSTOMER.MY_LINKS_PAGE.KEY,
    name: ROUTER.CUSTOMER.MY_LINKS_PAGE.NAME,
    link: ROUTER.CUSTOMER.MY_LINKS_PAGE.PATH,
    icon: markRaw(LinkIcon),
  });

  if (!isCustomerUser) {
    // User management
    menuItems.value.push({
      id: ROUTER.CUSTOMER.USER_MANAGEMENT_PAGE.KEY,
      name: ROUTER.CUSTOMER.USER_MANAGEMENT_PAGE.NAME,
      link: ROUTER.CUSTOMER.USER_MANAGEMENT_PAGE.PATH,
      icon: markRaw(UsersIcon),
    });
  }
};

const buildSideMenuDashboardItems = (): void => {
  if (!products.value) return;
  const sideMenuDashboardItem = menuItems.value.find((item) => item.id === ROUTER.CUSTOMER.DASHBOARDS_PAGE.KEY);
  if (!sideMenuDashboardItem) return;
  sideMenuDashboardItem.children = [];
  (hasProductChronicle.value || hasProductSentinelOne.value) &&
    sideMenuDashboardItem.children.push({
      id: ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.KEY,
      name: ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.NAME,
      link: ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.PATH,
    });
  hasProductChronicle.value &&
    sideMenuDashboardItem.children.push({
      id: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.KEY,
      name: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.NAME,
      link: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.PATH,
    });
  hasProductSentinelOne.value &&
    sideMenuDashboardItem.children.push({
      id: ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.KEY,
      name: ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.NAME,
      link: ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.PATH,
    });
  hasProductServiceNow.value &&
    sideMenuDashboardItem.children.push({
      id: ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.KEY,
      name: ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.NAME,
      link: ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.PATH,
    });
};

watch(products, async () => {
  buildSideMenuDashboardItems();
  await nextTick(() => {
    initFlowbite();
    resizeSideMenu();
  });
});

onMounted(() => {
  buildSideMenuItems();
  buildSideMenuDashboardItems();
  initFlowbite();
  resizeSideMenu();
  window.addEventListener('resize', resizeSideMenu);
});
onUnmounted(() => {
  window.removeEventListener('resize', resizeSideMenu);
});
</script>
