<!-- eslint-disable vue/no-v-html -->
<!-- 
  The html content is sanitized using DOMPurify before rendering it in the component.
  so that we can safely render the markdown content without worrying about XSS attacks.
  Disable the eslint rule vue/no-v-html for this file to avoid the warning.
 -->
<template>
  <div v-html="sanitizedHTML"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import markdownIt from 'markdown-it';
import markdownItLinkAttributes from 'markdown-it-link-attributes';
import DOMPurify from 'dompurify';

const props = defineProps<{
  markdown: string;
}>();

// Initialize markdown parser
const md = new markdownIt();
md.use(markdownItLinkAttributes, {
  attrs: {
    target: '_blank',
    rel: 'noopener noreferrer',
  },
});

// Compute sanitized HTML from markdown
const sanitizedHTML = computed(() => {
  const renderedMarkdown = md.render(props.markdown);
  return DOMPurify.sanitize(renderedMarkdown, { ADD_ATTR: ['target'] });
});
</script>
