export const waitUntil = (condition: () => boolean, timeout = 10000, interval = 100): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const timer = setInterval(() => {
      if (condition()) {
        clearInterval(timer);
        resolve(true);
      }
    }, interval);
    setTimeout(() => {
      clearInterval(timer);
      reject(new Error('Timeout'));
    }, timeout);
  });
};
