<template>
  <div class="hidden">
    <span class="sr-only">Validate Session Expiration</span>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/stores/auth.store';

import { subscribeAccessTokenChanged, unsubscribeAccessTokenChanged } from '@/utils/event.utils';

const { refreshAccessToken } = useAuthStore();

let timer, exprationTime;

// Set the interval time (in milliseconds) to check the token expiration
const expirationLifetime = 1000 * 60 * 30;
const intervalTime = expirationLifetime + 1000 * 30; // 30 minutes and 30 seconds

function checkTokenExpiration(): void {
  refreshAccessToken(true);
}

const activateTimer = (): void => {
  if (timer) {
    clearTimeout(timer as string);
  }
  timer = setTimeout(checkTokenExpiration, intervalTime);
};

const initializeServiceWorker = (): void => {
  subscribeAccessTokenChanged('access-token-changed', (): void => {
    // refresh expiration time
    exprationTime = Date.now() + expirationLifetime;

    activateTimer();
  });

  window.addEventListener('focus', (): void => {
    if (document.visibilityState === 'visible') {
      const currentTime = Date.now();

      if (currentTime > exprationTime) {
        refreshAccessToken(true);
      }
    }
  });
};

onMounted(() => {
  initializeServiceWorker();
});

onUnmounted(() => {
  clearTimeout(timer as string);
  unsubscribeAccessTokenChanged('access-token-changed');
});
</script>
