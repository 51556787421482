<script lang="ts">
export default {
  name: 'CustomerLayout',
};
</script>
<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth.store';

import AppNavBar from '@/components/app-layouts/AppNavBar.vue';
import AppSideBar from '@/components/app-layouts/AppSideBar.vue';
import AppFooter from '@/components/app-layouts/AppFooter.vue';

import LoadingPanel from '../LoadingPanel.vue';
import ScrollToTopButton from './ScrollToTopButton.vue';
import ImpersonationBar from '../impersonation/ImpersonationBar.vue';

import { publishAccessTokenChanged } from '@/utils/event.utils';

const { getAccessToken } = useAuthStore();

const { hasAuthenticated, getUser } = useAuthStore();
const isAuthenticated = hasAuthenticated();
const user = getUser();

onMounted(() => {
  const token = getAccessToken();
  publishAccessTokenChanged('access-token-changed', token);
});
</script>

<template>
  <template v-if="!isAuthenticated">
    <main class="relative h-full">
      <div class="h-full p-4">
        <LoadingPanel />
      </div>
    </main>
  </template>
  <template v-else-if="!user?.tenant">
    <main class="relative h-full">
      <div class="h-full p-4">
        <p class="text-center text-lg text-white">
          Your account isn't associated with any tenant. Please contact the Digitalhands administrator for support.
        </p>
      </div>
    </main>
  </template>
  <template v-else>
    <div class="flex flex-col min-h-full">
      <ImpersonationBar />
      <AppNavBar />
      <AppSideBar />
      <main class="relative xl:ml-64 grow flex flex-col justify-between">
        <div class="p-4 xl:pl-5 xl:pr-8 grow">
          <RouterView />
        </div>
        <AppFooter />
      </main>
    </div>
    <ScrollToTopButton />
  </template>
</template>
