<template>
  <div ref="parentRef" class="overflow-auto flex flex-col gap-3">
    <div
      :style="{
        height: `${totalSize}px`,
        width: '100%',
        position: 'relative',
      }"
    >
      <div
        class="items-list"
        :style="{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${virtualRows[0]?.start ?? 0}px)`,
        }"
      >
        <slot
          v-for="virtualRow in virtualRows"
          :key="virtualRow.key"
          :ref="measureElement"
          name="row-item"
          class="items-wrapper"
          :data-index="virtualRow.index"
          :index="virtualRow.index"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useVirtualizer, VirtualizerOptions } from '@tanstack/vue-virtual';
import { computed, ref, watchEffect } from 'vue';

const emits = defineEmits(['fetchNextPage']);

const props = defineProps<{
  itemCount: number;
  totalItemCount: number;
}>();

const parentRef = ref<HTMLElement | null>(null);
const rowVirtualizerOptions = computed((): VirtualizerOptions<HTMLElement, HTMLElement> => {
  return {
    count: props.totalItemCount > props.itemCount ? props.itemCount + 1 : props.itemCount,
    getScrollElement: () => parentRef.value,
    estimateSize: () => 100,
    overscan: 10,
  } as unknown as VirtualizerOptions<HTMLElement, HTMLElement>;
});
const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => rowVirtualizer.value.getTotalSize());
const measureElement = (el: HTMLElement): void => {
  if (!el) {
    return;
  }

  rowVirtualizer.value.measureElement(el);

  return undefined;
};

watchEffect(() => {
  const [lastItem] = [...virtualRows.value].reverse();
  if (!lastItem) {
    return;
  }

  if (lastItem.index >= props.itemCount - 1) {
    emits('fetchNextPage');
  }
});
</script>
