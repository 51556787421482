import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { AnyPrimitive } from '@/entities/common';
import { useAuthStore } from '../stores/auth.store';
import { useImpersonationStore } from '@/stores/impersonation.store';

export type FetchError = Error & {
  statusCode?: string;
  errorCode?: string;
};

export const fetchUrl = async (url: string, options?: RequestInit): Promise<Response> => {
  const resp = await fetch(url, options);
  if (resp.ok) {
    return resp;
  }
  if (resp.status >= 400) {
    const respBody = await resp.json();
    const errorMsg: string = respBody?.message || respBody?.error || resp.statusText;
    const err: FetchError = new Error(errorMsg);
    err.statusCode = respBody?.statusCode || resp.status;
    err.errorCode = respBody?.errorCode;
    err.name = respBody?.name || respBody?.error;
    err.message = respBody?.message || respBody?.error || resp.statusText;

    throw err;
  }
};

export const fetchJson = async (url: string, options?: RequestInit, toJson = true): Promise<Response> => {
  const headers = omitBy(
    {
      'Content-Type': 'application/json',
      ...options.headers,
    },
    isNil,
  );
  const response = await fetchUrl(url, {
    ...options,
    headers,
  });
  if (!toJson) {
    return response;
  }
  return response.json();
};

export const fetchJsonWithAccessToken = async (
  url: string,
  accessToken: string,
  options?: RequestInit,
  signoutOnUnauthorized = false,
  toJson = true,
  // Disale eslint rule because we don't know the shape of the response
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const { signout } = useAuthStore();
  try {
    const resp = await fetchJson(
      url,
      {
        ...options,
        headers: {
          ...options?.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      },
      toJson,
    );
    return resp;
  } catch (error) {
    if (error.statusCode === 401 && signoutOnUnauthorized) {
      signout();
      return;
    }
    throw error;
  }
};

// Disale eslint rule because we don't know the shape of the response
export const fetchJsonWithAuthorization = async <T = unknown>(
  url: string,
  options?: RequestInit,
  toJson = true,
): Promise<T> => {
  const { getAccessToken } = useAuthStore();
  const { refreshAccessToken } = useAuthStore();
  try {
    const accessToken = getAccessToken();
    const resp = await fetchJsonWithAccessToken(url, accessToken, options, false, toJson);
    return resp;
  } catch (error) {
    // If token is expired, refresh token and retry
    if (error.statusCode === 401) {
      // Refresh access token, or signout if can't refresh
      const newAccessToken = await refreshAccessToken(true);

      // Retry with new access token, or signout if it's still unauthorized
      return fetchJsonWithAccessToken(url, newAccessToken, options, true, toJson);
    }
    throw error;
  }
};

export const fetchJsonWithAuthorizationAndPersonation = async <T = unknown>(
  url: string,
  options?: RequestInit,
  toJson = true,
): Promise<T> => {
  const personationStore = useImpersonationStore();
  const { isImpersonating } = personationStore;
  if (!isImpersonating) {
    return fetchJsonWithAuthorization(url, options, toJson);
  }
  const optionsWithImpersonation = {
    ...options,
    headers: {
      ...options?.headers,
      'DH-IMPERSONATED-ID': personationStore.impersonatedUser.id,
    },
  };
  return fetchJsonWithAuthorization(url, optionsWithImpersonation, toJson);
};

export const buildQueryString = (params: Record<string, AnyPrimitive>): string => {
  const query = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      query.append(key, encodeURIComponent(value));
    }
  });
  // Decode query again to prevent URLSearchParams percent-encode strings
  return decodeURIComponent(query.toString());
};
