<script lang="ts">
export default {
  name: 'CustomerLayout',
};
</script>
<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { useAuthStore } from '@/stores/auth.store';

import AdminTenantSideBar from './AdminTenantSideBar.vue';
import TenantListBar from '@/views/admin-home/components/TenantListBar.vue';

import LoadingPanel from '@/components/LoadingPanel.vue';
import AppNavBar from '@/components/app-layouts/AppNavBar.vue';
import AppFooter from '@/components/app-layouts/AppFooter.vue';

import { ROUTER } from '@/app-constants/router.constant';
import { isAdministrator, IsCustomerSuccessManager } from '@/utils/user.utils';

const router = useRouter();
const { params: routerParams } = router.currentRoute.value;

const { hasAuthenticated, getUser } = useAuthStore();
const isAuthenticated = hasAuthenticated();
const authUser = getUser();
const isAdmin = isAdministrator(authUser);
const isCSM = IsCustomerSuccessManager(authUser);

const handleGoClick = (tenantId: string): void => {
  const currentRouterMeta = router.currentRoute.value.meta;
  if (currentRouterMeta?.sourcePath) {
    router.push({ path: (currentRouterMeta?.sourcePath as string).replace(':tenantId', tenantId) });
  } else {
    router.push({ path: `/admin/tenants/${tenantId}` });
  }
};

onMounted(() => {
  if (!(isAdmin || isCSM)) {
    router.push({ path: ROUTER.CUSTOMER.HOME_PAGE.PATH, replace: true });
  }
});
</script>

<template>
  <template v-if="!isAuthenticated || !(isAdmin || isCSM)">
    <main class="relative h-full">
      <div class="h-full p-4">
        <LoadingPanel />
      </div>
    </main>
  </template>
  <template v-else>
    <div class="flex flex-col min-h-full">
      <AppNavBar :show-tenant-name="false" />
      <AdminTenantSideBar />
      <main class="relative xl:ml-64 grow flex flex-col justify-between">
        <div class="p-4 xl:pl-5 xl:pr-8 flex flex-col gap-6">
          <TenantListBar
            class="sm:max-w-xl"
            :selected-tenant-value="routerParams.tenantId as string"
            @go-click="handleGoClick"
          />
          <RouterView />
        </div>
        <AppFooter />
      </main>
    </div>
  </template>
</template>
