<template>
  <template v-if="!impersonationStore.isImpersonating"></template>
  <template v-else>
    <div class="impersonating-bar">
      <div class="flex items-center gap-4">
        <span class="text-xs lg:text-sm">You are impersonating {{ impersonationStore.impersonatedUser.fullName }}</span>
        <Button class="button-primary btn-stop min-w-36" @click="handleStopImpersonation"
          ><span class="text-xs lg:text-sm">Stop impersonation</span></Button
        >
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { useImpersonationStore } from '@/stores/impersonation.store';
import Button from '../Button.vue';
import { useRouter } from 'vue-router';
import { ROUTER } from '@/app-constants/router.constant';
import { ImpersonateAction } from '@/entities/log.entity';
import { impersonationEventLog } from '@/services/admin/log.service';

const impersonationStore = useImpersonationStore();
const router = useRouter();

async function handleStopImpersonation(): Promise<void> {
  const nextPath = ROUTER.ADMIN_TENANT.HOME_PAGE.PATH.replace(
    ':tenantId',
    impersonationStore.impersonatedTenant.tenantAbbreviation,
  );
  const impersonatedUserId = impersonationStore.impersonatedUser.id;
  impersonationStore.stopImpersonation();
  await impersonationEventLog(ImpersonateAction.impersonation_end, impersonatedUserId);
  router.replace({ path: nextPath });
}
</script>
<style scoped>
.impersonating-bar {
  @apply bg-green-500;
  @apply flex gap-4 items-center justify-center;
  @apply px-4 py-2 xl:ml-[244px];
}
.btn-stop:deep(.btn-label) {
  @apply !text-wrap sm:text-nowrap;
}
</style>
