import { AuthUser } from '@/entities/authUser.entity';
import { Tenant } from '@/entities/tenant.entity';
import { useAuthStore } from '@/stores/auth.store';
import { useImpersonationStore } from '@/stores/impersonation.store';
import { isAdministrator, IsCustomerSuccessManager } from '@/utils/user.utils';

export type ImpersonationInfo = {
  isImpersonating: boolean;
  impersonatedUser: AuthUser;
  impersonatedTenant: Tenant;
  stopImpersonating: () => void;
  signout: () => void;
  checkIfAuthUserCanImpersonate: (authUser: AuthUser) => boolean;
  setImpersonatedTenant: (tenant: Tenant) => void;
};

export const useImpersonationInfo = (): ImpersonationInfo => {
  const { signout, getUser, setUser } = useAuthStore();
  const impersonationStore = useImpersonationStore();

  const isImpersonating = impersonationStore?.isImpersonating;
  const impersonatedUser = isImpersonating ? impersonationStore?.impersonatedUser : getUser();
  const impersonatedTenant = isImpersonating ? impersonationStore?.impersonatedTenant : impersonatedUser?.tenant;

  const checkIfAuthUserCanImpersonate = (authUser: AuthUser): boolean => {
    return isAdministrator(authUser) || IsCustomerSuccessManager(authUser);
  };

  const setImpersonatedTenant = (tenant: Tenant): void => {
    if (isImpersonating) {
      impersonationStore.setImpersonatedTenant({
        ...impersonatedTenant,
        ...tenant,
      });
    } else {
      setUser({
        ...impersonatedUser,
        tenant: {
          ...impersonatedTenant,
          ...tenant,
        },
      });
    }
  };

  return {
    isImpersonating,
    impersonatedUser,
    impersonatedTenant,
    stopImpersonating: impersonationStore.stopImpersonation,
    signout,
    checkIfAuthUserCanImpersonate,
    setImpersonatedTenant,
  };
};
