import { CacheLocation } from '@auth0/auth0-vue';
import { AUTH0_API_AUDIENCE, AUTH0_CALL_BACK_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../app-constants/env.constant';

export const OIDC_CONFIG = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: AUTH0_CALL_BACK_URL,
    audience: AUTH0_API_AUDIENCE,
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage' as CacheLocation,
};
