<template>
  <div class="tenant-selector-bar flex gap-4">
    <div class="grow overflow-hidden">
      <TenantSelector
        ref="tenantSelectorRef"
        :selected-tenant-value="selectedTenantValue"
        @change="handleTenantChange"
        @data-change="handleDataChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TenantSelector from './TenantSelector.vue';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { TenantSelectorItem, TenantSelectorRef } from './tenantSelector.type';
import { useAdminTenantStore } from '@/stores/adminTenant.storage';
import { Tenant } from '@/entities/tenant.entity';

const adminTenantStorage = useAdminTenantStore();

const tenantSelectorRef = ref<TenantSelectorRef | null>(null);
const selectedTenant = ref<Tenant>();

const props = withDefaults(
  defineProps<{
    selectedTenantValue?: string;
  }>(),
  {
    selectedTenantValue: undefined,
  },
);

const emits = defineEmits(['goClick']);

const router = useRouter();
const selectedTenantValueRef = ref<string>(props.selectedTenantValue);

const handleTenantChange = (value: string, selectedItem): void => {
  selectedTenant.value = selectedItem?.tenant;
  selectedTenantValueRef.value = value;
  handleGoClick();
};

const handleGoClick = (): void => {
  adminTenantStorage.setTenant(selectedTenant.value);
  emits('goClick', selectedTenantValueRef.value);
};

const buildPageTitle = (): string => {
  const currentRoute = router.currentRoute.value;
  const dynamicTitle = (currentRoute.meta?.dynamicTitle || currentRoute.meta?.title) as string;
  const title = dynamicTitle.replace(/{tenantName}/g, tenantSelectorRef.value?.selectedLabel || 'Tenant Admin');
  return title;
};
const setPageTitle = (): void => {
  document.title = buildPageTitle();
};
const handleDataChange = (data?: TenantSelectorItem[], selectedItem?: TenantSelectorItem): void => {
  selectedTenant.value = selectedItem?.tenant;
  adminTenantStorage.setTenant(selectedTenant.value);
  setPageTitle();
};

onMounted(() => {
  setPageTitle();
});
// on route change
watch(
  () => router.currentRoute.value,
  () => {
    setPageTitle();
  },
);
</script>
