<template>
  <DropdownSelect :items="data" :value="selectedValue" :loading="isLoading" @change="handleItemChange" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { useMutableQuery } from '@/hooks/useMutableQuery';
import { useAuthStore } from '@/stores/auth.store';

import { TenantSelectorItem, TenantSelectorRef } from './tenantSelector.type';

import DropdownSelect from '@/components/DropdownSelect.vue';

import { IsCustomerSuccessManager } from '@/utils/user.utils';

import { getTenants } from '@/services/admin/tenant.service';
import { getCSMTenants } from '@/services/admin/csm.service';
import { ROUTER } from '@/app-constants/router.constant';

const router = useRouter();
const { getUser } = useAuthStore();
const authUser = getUser();

const isCSM = IsCustomerSuccessManager(authUser);

const props = withDefaults(
  defineProps<{
    selectedTenantValue?: string;
  }>(),
  {
    selectedTenantValue: undefined,
  },
);

const emits = defineEmits(['dataChange', 'change']);

const [{ data, isLoading }] = useMutableQuery({
  queryKey: ['getTenants'],
  queryFn: async (): Promise<TenantSelectorItem[]> => {
    let response = [];
    if (isCSM) response = await getCSMTenants();
    else response = await getTenants();

    const result =
      response?.map((tenant) => ({
        label: tenant.tenantName,
        value: tenant.tenantAbbreviation,
        tenant,
      })) ?? [];

    return [...result].sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
  },
});

const selectedValue = ref<string>(props.selectedTenantValue);
const handleItemChange = (value: string): void => {
  selectedValue.value = value;
  const selectedItem = data.value?.find((item) => item.value === value);
  emits('change', value, selectedItem);
};

const selectedLabel = computed(() => {
  const selected = data.value?.find((item) => item.value === selectedValue.value);
  return selected?.label ?? '';
});

watch(
  () => data.value,
  () => {
    const selectedItem = data.value?.find((item) => item.value === selectedValue.value);

    if (selectedItem) {
      emits('dataChange', data, selectedItem);
    } else {
      router.push({ path: ROUTER.ADMIN.HOME_PAGE.PATH, replace: true });
    }
  },
);

const exposedObject: TenantSelectorRef = {
  selectedValue,
  selectedLabel,
};
defineExpose(exposedObject);
</script>
