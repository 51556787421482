import DeviceDetector, { DeviceDetectorResult } from 'device-detector-js';

export const getDeviceInfo = (): DeviceDetectorResult => {
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  return device;
};

const isIpad = (deviceInfo: DeviceDetectorResult): boolean => {
  return (
    deviceInfo.os.name === 'Mac' &&
    navigator &&
    'standalone' in navigator &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  );
};

export const getDeviceType = (): {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
} => {
  const device = getDeviceInfo();
  const deviceType = device.device?.type;

  const isMobile = deviceType === 'smartphone';
  const isTablet = !isMobile && (deviceType === 'tablet' || isIpad(device));
  const isDesktop = !isMobile && !isTablet && deviceType === 'desktop';
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
