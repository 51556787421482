import { z } from 'zod';
import { convertISODateToDate, fromISODate } from '@/utils/date.utils';

export const TenantDetailSchema = z.object({
  id: z.string(),
  tenantName: z.string(),
  tenantAbbreviation: z.string(),
  snowAccountId: z.string(),
  sfdcAccountId: z.string().optional().nullable(),
  status: z.number(),
  isSecurityPostureSurveyActivated: z.boolean(),
  isSecurityPostureSurveyCompleted: z.boolean(),
  createdAt: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => fromISODate(val)),
  modifiedAt: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => fromISODate(val)),
});

export const ManagedCustomerRawSchema = z.object({
  id: z.string(),
  userId: z.string(),
  tenantId: z.string(),
  fromDate: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => convertISODateToDate(val)),
  toDate: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => convertISODateToDate(val)),
  tenant: TenantDetailSchema,
  createdAt: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => fromISODate(val)),
  modifiedAt: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => fromISODate(val)),
  accessType: z.string().optional().nullable(),
});

export const ManagedCustomerSchema = ManagedCustomerRawSchema.transform((data) => ({
  ...data,
  accessType: data.toDate ? 'Temporary' : 'Permanent',
}));

export const ManagedCustomersSchema = z.array(ManagedCustomerSchema);

export type ManagedCustomer = z.infer<typeof ManagedCustomerSchema>;
export type ManagedCustomers = z.infer<typeof ManagedCustomersSchema>;
