import { CUSTOMER_SERVICE_URL } from '@/app-constants/env.constant';
import { Tenants, TenantsSchema } from '@/entities/tenant.entity';
import { fetchJsonWithAuthorization } from '@/utils/fetch.utils';
import { objectToEntity } from '@/utils/transform.utils';

const ROOT_URL = `${CUSTOMER_SERVICE_URL}/api/admin`;

export const getTenants = async (): Promise<Tenants> => {
  const url = `${ROOT_URL}/tenants`;

  const jsonObj = await fetchJsonWithAuthorization(url);

  return objectToEntity<Tenants>(TenantsSchema, jsonObj);
};
