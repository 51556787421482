import { defineStore } from 'pinia';

type AppState = {
  appBarLoadingCount: number;
};

export const useAppStore = defineStore('app-store', {
  state: (): AppState => ({
    appBarLoadingCount: 0,
  }),
  actions: {
    incrementAppBarLoadingCount() {
      this.appBarLoadingCount += 1;
    },
    decrementAppBarLoadingCount() {
      this.appBarLoadingCount -= 1;
    },
  },
});
