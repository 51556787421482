<template>
  <VirtualList
    :item-count="listItems.length"
    :total-item-count="virtualTotalItems"
    class="vitual-list"
    @fetch-next-page="handleFetchNextPage"
  >
    <template #row-item="{ index }">
      <template v-if="!!listItems[index]">
        <NotificationListItem
          v-if="listItems[index].type === 'item'"
          :item="listItems[index].item as NotificationItem"
          :is-expanded="(listItems[index].item as NotificationItem).isExpanded"
          @on-expanded-change="(isExpanded) => handleExpandedChange(index, isExpanded)"
        ></NotificationListItem>
        <div v-else-if="listItems[index].type === 'unread-head'" class="group-header pb-4">
          <h5>Unread</h5>
          <Button class="button-text" :loading="isMarkingAllAsRead" @click="handleMarkAllAsRead">
            <template #start-adornment>
              <CheckReadLinearIcon class="size-8" />
            </template>
            <span>Mark all as read</span>
          </Button>
        </div>
        <div v-else-if="listItems[index].type === 'read-head'" class="group-header py-4">
          <h5>Read</h5>
        </div>
      </template>
    </template>
  </VirtualList>
</template>
<script setup lang="ts">
import { Notification } from '@/entities/notification.entity';
import NotificationListItem from './NotificationListItem.vue';

import { ref, watch, watchEffect } from 'vue';
import VirtualList from '../VirtualList.vue';
import CheckReadLinearIcon from '../icons/CheckReadLinearIcon.vue';
import Button from '../Button.vue';
import { getNotifications, markAllNotificationAsRead } from '@/services/notification.service';

type NotificationPropsItems = {
  read: Notification[];
  unread: Notification[];
};
const props = defineProps<{
  items?: NotificationPropsItems;
  totalItems?: number;
  totalPages?: number;
}>();

const emits = defineEmits(['expandedChange', 'markAllAsRead']);

type NotificationItem = Notification & {
  isExpanded?: boolean;
};

type NotificationVirtualListItem = {
  type: 'unread-head' | 'read-head' | 'item';
  item: NotificationItem | string;
};

const displayItems = ref<NotificationPropsItems>(props.items ?? { read: [], unread: [] });
watchEffect(() => {
  displayItems.value = props.items;
});

const listItems = ref<NotificationVirtualListItem[]>([]);
const virtualTotalItems = ref<number>(0);
watch(
  () => displayItems.value,
  () => {
    const items: NotificationVirtualListItem[] = [];
    const hasUnread = displayItems.value?.unread.length > 0;
    let extraItemCount = 0;
    if (hasUnread) {
      extraItemCount += 1;
      items.push({ type: 'unread-head', item: 'Unread' });
      items.push(
        ...(displayItems.value?.unread ?? []).map((item) => ({ type: 'item', item }) as NotificationVirtualListItem),
      );
    }
    if (displayItems.value?.read.length > 0) {
      extraItemCount += 1;
      items.push({ type: 'read-head', item: 'Read' });
      items.push(
        ...(displayItems.value?.read ?? []).map((item) => ({ type: 'item', item }) as NotificationVirtualListItem),
      );
    }
    listItems.value = items;
    virtualTotalItems.value = (props.totalItems ?? 0) + extraItemCount;
  },
);

const currentPage = ref(1);
const isFetchingNextPage = ref(false);
const handleFetchNextPage = async (): Promise<void> => {
  if (isFetchingNextPage.value) {
    return;
  }
  if (currentPage.value + 1 > (props.totalPages ?? 1)) {
    return;
  }
  isFetchingNextPage.value = true;
  currentPage.value += 1;
  try {
    const [notifications] =
      (await getNotifications({
        page: currentPage.value,
        order: 'desc',
      })) ?? [];
    const notificationGroups = notifications.reduce(
      (
        acc: {
          read: Notification[];
          unread: Notification[];
        },
        notification,
      ) => {
        if (notification.isRead) {
          acc.read.push(notification);
        } else {
          acc.unread.push(notification);
        }
        return acc;
      },
      {
        read: [],
        unread: [],
      },
    );

    displayItems.value = {
      read: [...displayItems.value.read, ...notificationGroups.read],
      unread: [...displayItems.value.unread, ...notificationGroups.unread],
    };
  } finally {
    isFetchingNextPage.value = false;
  }
};

const handleExpandedChange = (index: number, isExpanded: boolean): void => {
  let item = { ...(listItems.value[index].item as NotificationItem), isExpanded };
  listItems.value = [
    ...listItems.value.slice(0, index),
    { ...listItems.value[index], item },
    ...listItems.value.slice(index + 1),
  ];
};

const isMarkingAllAsRead = ref(false);
const handleMarkAllAsRead = async (): Promise<void> => {
  isMarkingAllAsRead.value = true;
  await markAllNotificationAsRead();
  isMarkingAllAsRead.value = false;
  emits('markAllAsRead');
};
</script>

<style scoped>
.group-header {
  @apply flex items-center justify-between;
  @apply text-text-support text-xs sm:text-sm font-normal;
  @apply uppercase;
  border: 0;
}
.group-header + div {
  border: 0;
}
.vitual-list:deep(.items-list) {
  @apply divide-y divide-gray-100 divide-opacity-15;
}
</style>
