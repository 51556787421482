import { Plugin, createApp } from 'vue';
import { createPinia } from 'pinia';
import { createNotivue } from 'notivue';
import { createAuth0 } from '@auth0/auth0-vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import VueSafeHTML from 'vue-safe-html';

import App from '@/App.vue';
import router from './routers/index';
import { OIDC_CONFIG } from './utils/oidc.utils';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config.js';

import './datatdog';

const fullConfig = resolveConfig(tailwindConfig);
const { theme } = fullConfig;
import VueApexCharts from 'vue3-apexcharts';
// Import styles
import '@/style.css';

function init(): void {
  const notivue = createNotivue();
  const app = createApp(App);
  app.provide('theme', theme);

  // Register VueQueryPlugin to app
  app.use(VueQueryPlugin);
  app.use(notivue);
  app.use(VueApexCharts as Plugin);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  app.use(VueSafeHTML);
  // Register Pinia to app
  app.use(createPinia());

  // Add router after loading of extensions to ensure all routes are registered
  app.use(router);

  // Setup & Register Auth0
  const AuthService = createAuth0(OIDC_CONFIG, {
    skipRedirectCallback: true,
  });
  app.use(AuthService);

  app.mount('#app');

  // Prevent the browser from opening files that are dragged on the window
  window.addEventListener('dragover', (e) => e.preventDefault(), false);
  window.addEventListener('drop', (e) => e.preventDefault(), false);
}

init();
