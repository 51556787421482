import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';
import isEmpty from 'lodash/isEmpty';

import { ROUTER } from '../app-constants/router.constant';

import { useAuthStore } from '../stores/auth.store';
import { LAYOUTS } from '../app-constants/layout.constant';
import { useAppStore } from '../stores/app.store';
import { useImpersonationInfo } from '@/hooks/useImpersonationInfo';
import { validateNextRoute } from '@/utils/router.utils';
import { isAdministrator, IsCustomerSuccessManager } from '@/utils/user.utils';
import { authUserStorage } from '@/storages/browser.storage';

const defaultRoutes: RouteRecordRaw[] = [
  /*
   * Routes for Customer
   */
  {
    name: ROUTER.CUSTOMER.HOME_PAGE.KEY,
    path: ROUTER.CUSTOMER.HOME_PAGE.PATH,
    component: () => import('@/views/customer-home/CustomerHomeView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.HOME_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    path: ROUTER.CUSTOMER.DASHBOARDS_PAGE.PATH,
    redirect: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.PATH, // Redirect to `Dashboard - CyGuard Cloud SIEM`
    meta: {
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.KEY, // CyGuard Cloud SIEM
    path: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.PATH,
    component: () => import('@/views/customer-dashboard-siem/CustomerDashboardSIEMView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.KEY, // MITRE ATT&CK Coverage
    path: ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.PATH,
    component: () => import('@/views/customer-dashboard-mitre/CustomerDashboardMITREView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.KEY, // CyGuard Cloud EDR
    path: ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.PATH,
    component: () => import('@/views/customer-dashboard-edr/CustomerDashboardEDRView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.KEY, // Cases
    path: ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.PATH,
    component: () => import('@/views/customer-dashboard-cases/CustomerDashboardCasesView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.FILES_PAGE.KEY,
    path: ROUTER.CUSTOMER.FILES_PAGE.PATH,
    component: () => import('@/views/customer-files/CustomerFilesView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.FILES_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.ACTIVITIES_PAGE.KEY,
    path: ROUTER.CUSTOMER.ACTIVITIES_PAGE.PATH,
    component: () => import('@/views/customer-activity/CustomerActivityView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.ACTIVITIES_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.MY_LINKS_PAGE.KEY,
    path: ROUTER.CUSTOMER.MY_LINKS_PAGE.PATH,
    component: () => import('@/views/customer-my-links/CustomerMyLinksView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.MY_LINKS_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.SERVICE_PAGE.KEY,
    path: ROUTER.CUSTOMER.SERVICE_PAGE.PATH,
    component: () => import('@/views/customer-services/CustomerServicesView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.SERVICE_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.SERVICENOW_DETAIL_PAGE.KEY,
    path: ROUTER.CUSTOMER.SERVICENOW_DETAIL_PAGE.PATH,
    component: () => import('@/views/customer-securitynow-tickets/CustomerServiceNowDetail.vue'),
    meta: {
      title: ROUTER.CUSTOMER.SERVICENOW_DETAIL_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
      shouldScrollToTop: true,
    },
    props: true,
  },
  {
    name: ROUTER.CUSTOMER.NOTIFICATION_PAGE.KEY,
    path: ROUTER.CUSTOMER.NOTIFICATION_PAGE.PATH,
    component: () => import('@/views/customer-notifications/CustomerNotificationsView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.NOTIFICATION_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.CONTACT_US_PAGE.KEY,
    path: ROUTER.CUSTOMER.CONTACT_US_PAGE.PATH,
    component: () => import('@/views/customer-contact-us/CustomerContactUsView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.CONTACT_US_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.SECURITY_POSTURE_SURVEY_PAGE.KEY,
    path: ROUTER.CUSTOMER.SECURITY_POSTURE_SURVEY_PAGE.PATH,
    component: () => import('@/views/customer-security-survey/CustomerSecuritySurveyView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.SECURITY_POSTURE_SURVEY_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.MY_CASES_PAGE.KEY,
    path: ROUTER.CUSTOMER.MY_CASES_PAGE.PATH,
    component: () => import('@/views/customer-securitynow-tickets/CustomerServiceNowTicketsView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.MY_CASES_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  {
    name: ROUTER.CUSTOMER.USER_MANAGEMENT_PAGE.KEY,
    path: ROUTER.CUSTOMER.USER_MANAGEMENT_PAGE.PATH,
    component: () => import('@//views/customer-user-management/CustomerUserManagementView.vue'),
    meta: {
      title: ROUTER.CUSTOMER.USER_MANAGEMENT_PAGE.TITLE,
      layout: LAYOUTS.CUSTOMER.name,
    },
  },
  /*
   * Routes for Admin
   */
  {
    name: ROUTER.ADMIN.HOME_PAGE.KEY,
    path: ROUTER.ADMIN.HOME_PAGE.PATH,
    component: () => import('@/views/admin-home/AdminHomeView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN.name,
      title: ROUTER.ADMIN.HOME_PAGE.TITLE,
    },
  },
  {
    name: ROUTER.ADMIN.TENANTS_PAGE.KEY,
    path: ROUTER.ADMIN.TENANTS_PAGE.PATH,
    component: () => import('@/views/admin-home/AdminHomeView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN.name,
      title: ROUTER.ADMIN.TENANTS_PAGE.TITLE,
    },
  },
  {
    name: ROUTER.ADMIN.FILES_PAGE.KEY,
    path: ROUTER.ADMIN.FILES_PAGE.PATH,
    component: () => import('@/views/admin-files/AdminFilesView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN.name,
      title: ROUTER.ADMIN.FILES_PAGE.TITLE,
    },
  },
  {
    name: ROUTER.ADMIN.CSM_PAGE.KEY,
    path: ROUTER.ADMIN.CSM_PAGE.PATH,
    component: () => import('@/views/admin-csm/AdminCSMView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN.name,
      title: ROUTER.ADMIN.CSM_PAGE.TITLE,
    },
  },
  {
    name: ROUTER.ADMIN.CSM_MANAGED_CUSTOMERS_PAGE.KEY,
    path: ROUTER.ADMIN.CSM_MANAGED_CUSTOMERS_PAGE.PATH,
    component: () => import('@/views/admin-csm/AdminCSMManagedCustomersView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN.name,
      title: ROUTER.ADMIN.CSM_MANAGED_CUSTOMERS_PAGE.TITLE,
    },
  },
  /*
   * Routes for Admin Tenant
   */
  {
    name: ROUTER.ADMIN_TENANT.HOME_PAGE.KEY,
    path: ROUTER.ADMIN_TENANT.HOME_PAGE.PATH,
    component: () => import('@/views/admin-tenant/AdminTenantHomeView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN_TENANT.name,
      title: ROUTER.ADMIN_TENANT.HOME_PAGE.TITLE,
      dynamicTitle: ROUTER.ADMIN_TENANT.HOME_PAGE.DYNAMIC_TITLE,
      sourcePath: ROUTER.ADMIN_TENANT.HOME_PAGE.PATH,
    },
  },
  {
    name: ROUTER.ADMIN_TENANT.LOGS_PAGE.KEY,
    path: ROUTER.ADMIN_TENANT.LOGS_PAGE.PATH,
    component: () => import('@/views/admin-tenant/AdminTenantLogsView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN_TENANT.name,
      title: ROUTER.ADMIN_TENANT.LOGS_PAGE.TITLE,
      dynamicTitle: ROUTER.ADMIN_TENANT.LOGS_PAGE.DYNAMIC_TITLE,
      sourcePath: ROUTER.ADMIN_TENANT.LOGS_PAGE.PATH,
    },
  },
  {
    name: ROUTER.ADMIN_TENANT.FILES_PAGE.KEY,
    path: ROUTER.ADMIN_TENANT.FILES_PAGE.PATH,
    component: () => import('@/views/admin-tenant/AdminTenantFilesView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN_TENANT.name,
      title: ROUTER.ADMIN_TENANT.FILES_PAGE.TITLE,
      dynamicTitle: ROUTER.ADMIN_TENANT.FILES_PAGE.DYNAMIC_TITLE,
      sourcePath: ROUTER.ADMIN_TENANT.FILES_PAGE.PATH,
    },
  },
  {
    name: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.KEY,
    path: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.PATH,
    component: () => import('@/views/admin-tenant/AdminTenantSecurityPostureView.vue'),
    meta: {
      layout: LAYOUTS.ADMIN_TENANT.name,
      title: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.TITLE,
      dynamicTitle: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.DYNAMIC_TITLE,
      sourcePath: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.PATH,
    },
  },

  // Auth Router
  {
    name: ROUTER.AUTH_CALLBACK.KEY,
    path: ROUTER.AUTH_CALLBACK.PATH,
    component: () => import('@/views/auth/AuthCallBackView.vue'),
    meta: {
      isPublic: true,
      layout: LAYOUTS.SIGNUP.name,
    },
  },
  {
    name: ROUTER.AUTH_SIGNUP.KEY,
    path: ROUTER.AUTH_SIGNUP.PATH,
    component: () => import('@/views/auth/SignupView.vue'),
    meta: {
      isPublic: true,
      layout: LAYOUTS.SIGNUP.name,
    },
  },
  {
    name: ROUTER.AUTH_EMAIL_VERIFY.KEY,
    path: ROUTER.AUTH_EMAIL_VERIFY.PATH,
    component: () => import('@/views/auth/EmailVerifyView.vue'),
    meta: {
      isPublic: true,
      layout: LAYOUTS.SIGNUP.name,
    },
  },
  {
    name: ROUTER.AUTH_ACCOUNT_INACTIVATED.KEY,
    path: ROUTER.AUTH_ACCOUNT_INACTIVATED.PATH,
    component: () => import('@/views/auth/AuthFailView.vue'),
    meta: {
      isPublic: true,
      layout: LAYOUTS.SIGNUP.name,
    },
  },

  // redirect to home page if route not found
  {
    path: '/:pathMatch(.*)*',
    redirect: (): object => {
      const authUser = authUserStorage.get();
      if (!authUser) {
        return { path: ROUTER.CUSTOMER.HOME_PAGE.PATH };
      }
      if (isAdministrator(authUser) || IsCustomerSuccessManager(authUser)) {
        return { path: ROUTER.ADMIN.HOME_PAGE.PATH };
      }
      return { path: ROUTER.CUSTOMER.HOME_PAGE.PATH };
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: defaultRoutes,
});

router.beforeEach(async (to) => {
  const { incrementAppBarLoadingCount } = useAppStore();
  const { loginWithRedirect } = useAuth0();
  const { getUser } = useAuthStore();
  const { isImpersonating, checkIfAuthUserCanImpersonate, stopImpersonating } = useImpersonationInfo();

  const authUser = getUser();

  if (!to.meta.isPublic && isEmpty(authUser)) {
    loginWithRedirect();
    return false;
  }

  if (isImpersonating && !checkIfAuthUserCanImpersonate(authUser)) {
    stopImpersonating();
  }

  const nextRoute = await validateNextRoute(to, authUser);
  if (nextRoute === false || (typeof nextRoute === 'string' && nextRoute !== to.path)) {
    return nextRoute;
  }
  incrementAppBarLoadingCount();
});

router.afterEach((to, from) => {
  const { decrementAppBarLoadingCount } = useAppStore();
  document.title = typeof to.meta?.title === 'string' ? to.meta.title : ROUTER.APP_NAME;
  decrementAppBarLoadingCount();

  const shouldScrollToTopAfterRedirect =
    from.name !== to.name || (from.name === to.name && !!to.meta?.shouldScrollToTop);

  if (shouldScrollToTopAfterRedirect) window.scrollTo(0, 0);
});

export default router;
