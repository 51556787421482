<template>
  <footer class="w-full h-[72px] bg-white rounded-lg shadow dark:bg-background antialiased">
    <div class="flext items-center mt-2.5">
      <p
        class="p-4 text-sm font-public font-normal leading-[21px] text-center dark:text-text-support sm:text-base sm:leading-6"
      >
        &copy; 2024 Digital Hands, LLC. All rights reserved.
      </p>
    </div>
  </footer>
</template>
