<template>
  <div>
    <div
      ref="textContainer"
      :class="{ 'text-collapsed': !isExpanded }"
      :style="{
        maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
      }"
    >
      <slot />
    </div>
    <button v-if="isExpandable" class="text-primary-300 text-sm" @click="toggle">
      {{ isExpanded ? '...view less' : 'view more...' }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    maxHeight: number;
    expanded: boolean;
  }>(),
  {
    maxHeight: 48,
    expanded: false,
  },
);

const isExpanded = ref(props.expanded);
const isOverflow = ref(false);
const isExpandable = ref(false);
const textContainer = ref<HTMLElement>();

// define onExpand and onCollapse events
const emits = defineEmits(['onExpandedChange']);

const checkOverflow = (): void => {
  if (textContainer.value) {
    const { scrollHeight, clientHeight } = textContainer.value;
    isOverflow.value = scrollHeight > clientHeight;
  }
};

const checkIsExapandable = (): boolean => {
  if (textContainer.value) {
    const { scrollHeight } = textContainer.value;
    return scrollHeight > props.maxHeight;
  }
  return false;
};

const toggle = (): void => {
  isExpanded.value = !isExpanded.value;
  emits('onExpandedChange', isExpanded.value);
};

let observer: MutationObserver;

watch(
  () => props.maxHeight,
  () => {
    isExpandable.value = checkIsExapandable();
  },
);

onMounted(() => {
  isExpandable.value = checkIsExapandable();
  observer = new MutationObserver(checkOverflow);
  if (textContainer.value) {
    observer.observe(textContainer.value, { childList: true, subtree: true });
  }
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<style scoped>
.text-collapsed {
  overflow: hidden;
}
</style>
