import { PREDEFINED_ROLES } from '../app-constants/role.constant';
import { AuthUser } from '../entities/authUser.entity';
import { Role } from '../entities/role.entity';
export const getUserFullName = (user: AuthUser): string => {
  if (!user) return '';
  return `${user.firstName} ${user.lastName}`.trim();
};

export const getUserFirstRole = (user: AuthUser): Role => {
  return user?.roles?.[0];
};

export const checkUserHasRoles = (user: AuthUser, roles: number[]): boolean => {
  const checkingRoles = new Set(roles);
  return user?.roles?.some((role) => checkingRoles.has(role.roleKey));
};
export const isAdministrator = (user: AuthUser): boolean => {
  return checkUserHasRoles(user, [PREDEFINED_ROLES.SYSTEM_ADMIN.id, PREDEFINED_ROLES.DH_ADMIN.id]);
};

export const IsCustomerSuccessManager = (user: AuthUser): boolean => {
  return checkUserHasRoles(user, [PREDEFINED_ROLES.CUSTOMER_SUCCESS_MANAGER.id]);
};

export const getLetterAvatarImage = (user: AuthUser): string => {
  // if backgroundColor is missing
  // choose a random color from palette
  const text = `${user?.firstName[0]}${user?.lastName[0]}`.toUpperCase();
  const letterIndex = text[0].charCodeAt(0) - 64;
  const hue = (360 / 26) * letterIndex;
  const backgroundColor = `hsl(${hue}, 68%, 48%)`;
  // selects the foregroundColor (black or white)
  // based on the backgroundColor
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 2), 16);
  const b = parseInt(hex.slice(4, 2), 16);
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  const theshold = 160;
  // sets the foreground color
  const foregroundColor = hsp > theshold ? '#000000' : '#ffffff';

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = 200;
  canvas.height = 200;

  // Draw background
  context.fillStyle = backgroundColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  // Get the computed style of the parent element
  const computedStyle = window.getComputedStyle(document.body);
  // Get the font property from the computed style
  const parentFont = computedStyle.getPropertyValue('font-family');

  context.font = `bold 100px ${parentFont}`;
  context.fillStyle = foregroundColor;
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(text, canvas.width / 2, canvas.height / 2);
  return canvas.toDataURL('image/png');
};

export const getTokenExpirationTime = (token: string): number => {
  const tokenPayload = token.split('.')[1];
  const base64Url = tokenPayload.replace(/-/g, '+').replace(/_/g, '/');
  const base64 = decodeURIComponent(
    self
      .atob(base64Url)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  const payload = JSON.parse(base64);

  // Get the expiration time in seconds
  const expirationTime = payload.exp;
  const expirationTimeMs = expirationTime * 1000;

  // Return the expiration time as a Date object
  return expirationTimeMs;
};

const getRoleLevelOfUser = (user: AuthUser): number => {
  if (checkUserHasRoles(user, [PREDEFINED_ROLES.SYSTEM_ADMIN.id])) {
    return PREDEFINED_ROLES.SYSTEM_ADMIN.level;
  }

  if (checkUserHasRoles(user, [PREDEFINED_ROLES.DH_ADMIN.id])) {
    return PREDEFINED_ROLES.DH_ADMIN.level;
  }

  if (checkUserHasRoles(user, [PREDEFINED_ROLES.CUSTOMER_SUCCESS_MANAGER.id])) {
    return PREDEFINED_ROLES.CUSTOMER_SUCCESS_MANAGER.level;
  }

  if (checkUserHasRoles(user, [PREDEFINED_ROLES.CUSTOMER_ADMIN.id])) {
    return PREDEFINED_ROLES.CUSTOMER_ADMIN.level;
  }

  if (checkUserHasRoles(user, [PREDEFINED_ROLES.CUSTOMER.id])) {
    return PREDEFINED_ROLES.CUSTOMER.level;
  }

  return null;
};

export const hasHigherPrivileged = (user: AuthUser, comparedUser: AuthUser): boolean => {
  const levelOfCurrenUser = getRoleLevelOfUser(user);
  const levelOfComparedUser = getRoleLevelOfUser(comparedUser);

  if (!levelOfCurrenUser || !levelOfComparedUser) return false;

  return levelOfCurrenUser <= levelOfComparedUser;
};
