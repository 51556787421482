import { ROUTER } from '@/app-constants/router.constant';
import { useAccountInformation } from '@/hooks/useAccountInformation';
import { RouteLocationNormalized } from 'vue-router';
import { waitUntil } from './promise.utils';
import { AuthUser } from '@/entities/authUser.entity';
import { isAdministrator, IsCustomerSuccessManager } from '@/utils/user.utils';

const isDashboardRoute = (to: RouteLocationNormalized): boolean => {
  const path = to.path;
  return (
    path === ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.PATH ||
    path === ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.PATH ||
    path === ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.PATH ||
    path === ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.PATH
  );
};

const validateAdministratorRoutes = (): boolean => {
  return true;
};

const validateCSMRoutes = (to: RouteLocationNormalized): string | boolean => {
  if (to.path === ROUTER.ADMIN.CSM_PAGE.PATH || to.path === ROUTER.ADMIN.CSM_MANAGED_CUSTOMERS_PAGE.PATH) {
    return ROUTER.ADMIN.HOME_PAGE.PATH;
  }

  return true;
};

const validateCustomeroutes = async (to: RouteLocationNormalized): Promise<string | boolean> => {
  if (isDashboardRoute(to)) {
    const { isLoading, hasProductChronicle, hasProductSentinelOne, hasProductServiceNow } = useAccountInformation();
    await waitUntil(() => !isLoading.value);

    if (
      to.path === ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.PATH &&
      (hasProductChronicle.value || hasProductSentinelOne.value)
    ) {
      return ROUTER.CUSTOMER.DASHBOARDS_MITRE_PAGE.PATH;
    } else if (to.path === ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.PATH && hasProductChronicle.value) {
      return ROUTER.CUSTOMER.DASHBOARDS_SIEM_PAGE.PATH;
    } else if (to.path === ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.PATH && hasProductSentinelOne.value) {
      return ROUTER.CUSTOMER.DASHBOARDS_EDR_PAGE.PATH;
    } else if (to.path === ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.PATH && hasProductServiceNow.value) {
      return ROUTER.CUSTOMER.DASHBOARDS_CASE_PAGE.PATH;
    } else {
      return ROUTER.CUSTOMER.HOME_PAGE.PATH;
    }
  }

  return true;
};

export const validateNextRoute = async (to: RouteLocationNormalized, user: AuthUser): Promise<string | boolean> => {
  const isAdmin = isAdministrator(user);
  const isCSM = IsCustomerSuccessManager(user);

  if (isAdmin) {
    return validateAdministratorRoutes();
  } else if (isCSM) {
    return validateCSMRoutes(to);
  } else {
    // If the next route is a dashboard route, check if the user has the necessary products
    return await validateCustomeroutes(to);
  }
};
