import { z } from 'zod';
export const PaginationInfoSchema = z.object({
  page: z.number(),
  pageSize: z.number(),
  totalRecords: z.number(),
  totalPages: z.number(),
});

export type PaginationInfo = z.infer<typeof PaginationInfoSchema>;

export type PaginationDto = {
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
};

export type PaginationWithSearchDto = {
  search?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
};
