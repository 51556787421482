import { AuthUser } from '@/entities/authUser.entity';
import { Tenant } from '@/entities/tenant.entity';
import { defineStore } from 'pinia';
import { ImpersonationStore } from './impersonation.store.types';
import { impersonationStorage } from '@/storages/browser.storage';

export const useImpersonationStore = defineStore('impersonation-store', {
  state: (): ImpersonationStore => {
    const storedImpersonation = impersonationStorage.get();
    return {
      isImpersonating: storedImpersonation?.isImpersonating ?? false,
      impersonatedUser: storedImpersonation?.impersonatedUser,
      impersonatedTenant: storedImpersonation?.impersonatedTenant,
    };
  },
  actions: {
    impersonate(impersonatedUser: AuthUser, impersonatedTenant: Tenant) {
      this.impersonatedUser = impersonatedUser;
      this.impersonatedTenant = impersonatedTenant;
      this.isImpersonating = true;

      // Persist impersonation state to local storage for page reload
      impersonationStorage.set({
        isImpersonating: true,
        impersonatedUser,
        impersonatedTenant,
      });
    },
    stopImpersonation() {
      this.impersonatedUser = undefined;
      this.impersonatedTenant = undefined;
      this.isImpersonating = false;
      impersonationStorage.remove();
    },
    setImpersonatedTenant(tenant: Tenant) {
      this.impersonatedTenant = {
        ...this.impersonatedTenant,
        ...tenant,
      };
      impersonationStorage.set({
        isImpersonating: this.isImpersonating,
        impersonatedUser: this.impersonatedUser,
        impersonatedTenant: this.impersonatedTenant,
      });
    },
  },
});
