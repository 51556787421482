import { fetchJsonWithAuthorizationAndPersonation } from '../utils/fetch.utils';
import { CUSTOMER_SERVICE_URL } from '../app-constants/env.constant';
import { AccountInformation, AccountInformationSchema } from '../entities/accountInfo.entity';
import { objectToEntity } from '../utils/transform.utils';
import { FacadeAPIGeneralResponse } from '../entities/common';

const ROOT_URL = `${CUSTOMER_SERVICE_URL}/api`;

export const getAccountInformation = async (): Promise<AccountInformation> => {
  const url = `${ROOT_URL}/accounts/me`;
  const jsonObj: FacadeAPIGeneralResponse = await fetchJsonWithAuthorizationAndPersonation(url);

  return objectToEntity<AccountInformation>(AccountInformationSchema, jsonObj.result as AccountInformation);
};
