import { fetchJsonWithAuthorizationAndPersonation } from '../utils/fetch.utils';
import { CUSTOMER_SERVICE_URL } from '../app-constants/env.constant';
import { FacadeAPIGeneralResponse } from '../entities/common';
import { objectToEntity } from '@/utils/transform.utils';
import { Notifications, NotificationsSchema, NotificationType } from '@/entities/notification.entity';
import { PaginationInfo, PaginationInfoSchema } from '@/entities/paginationInfo.entity';

const ROOT_URL = `${CUSTOMER_SERVICE_URL}/api`;

export const createNotification = async (
  subject: string,
  message: string,
  type: NotificationType,
): Promise<Notifications> => {
  const url = `${ROOT_URL}/notifications`;

  const options = {
    method: 'POST',
    body: JSON.stringify({ subject, message, type }),
  };
  const jsonObj = await fetchJsonWithAuthorizationAndPersonation<FacadeAPIGeneralResponse>(url, options);

  return objectToEntity<Notifications>(NotificationsSchema, jsonObj.result);
};

export const markAllNotificationAsRead = async (): Promise<Notifications> => {
  const url = `${ROOT_URL}/notifications/actions/mark-notifications-as-read`;

  const options = {
    method: 'PUT',
  };
  const jsonObj = await fetchJsonWithAuthorizationAndPersonation<FacadeAPIGeneralResponse>(url, options);
  return objectToEntity<Notifications>(NotificationsSchema, jsonObj.result);
};

export const markNotificationAsRead = async (notificationId: string): Promise<Notifications> => {
  const url = `${ROOT_URL}/notifications/actions/mark-notifications-as-read/${notificationId}`;

  const options = {
    method: 'PUT',
  };
  const jsonObj = await fetchJsonWithAuthorizationAndPersonation<FacadeAPIGeneralResponse>(url, options);
  return objectToEntity<Notifications>(NotificationsSchema, jsonObj.result);
};

export const getNotifications = async ({
  limit = 10,
  order = 'desc',
  page,
}: {
  limit?: number;
  order?: 'asc' | 'desc';
  page?: number;
} = {}): Promise<[Notifications, PaginationInfo]> => {
  const _page = page ? `&page=${page}` : '';
  const url = `${ROOT_URL}/notifications?page_size=${limit}&sort_order=${order}${_page}`;

  const jsonObj: { items: unknown } = await fetchJsonWithAuthorizationAndPersonation(url);
  const { items, ...pagination } = jsonObj;
  const notifications = objectToEntity<Notifications>(NotificationsSchema, items);
  const paginationInfo = objectToEntity<PaginationInfo>(PaginationInfoSchema, pagination);

  return [notifications, paginationInfo];
};
