import { SafeParseError, ZodError, ZodSchema } from 'zod';
import { AnyPrimitive } from '../entities/common';
import { toCamelCase } from './object.utils';

export const tableDataToEntities = <TEntity>(
  schema: ZodSchema,
  data: {
    headers: string[];
    values: AnyPrimitive[][];
  },
  headersMap: { [K in keyof TEntity]?: string },
): TEntity[] => {
  const headerIndexesMap: { [K in keyof TEntity]?: number } = Object.keys(headersMap).reduce(
    (acc, key) => {
      const header = headersMap[key] as string;
      const index = data.headers.indexOf(header);
      if (index < 0) {
        return acc;
      }
      acc[key] = index;
      return acc;
    },
    {} as { [K in keyof TEntity]?: number },
  );
  return data.values.map((value): TEntity => {
    const obj: { [key: string]: AnyPrimitive } = {};
    Object.keys(headerIndexesMap).forEach((key) => {
      obj[key] = value[headerIndexesMap[key]];
    });
    try {
      const data = schema.parse(obj);
      return data as TEntity;
    } catch (e) {
      console.error('PARSING ERROR', e);
      return undefined;
    }
  });
};

export const objectToEntity = <TEntity>(schema: ZodSchema, data: unknown, convertCamelCase = true): TEntity => {
  const rawObj = convertCamelCase ? toCamelCase<TEntity>(data as object) : data;
  const result = schema.safeParse(rawObj);
  if (!result.success) {
    const error = (result as SafeParseError<object>).error;
    const parseError: Error & { zodError?: ZodError } = new Error('PARSING ERROR');
    parseError.zodError = error;

    console.error('PARSING ERROR', parseError.zodError);

    throw parseError;
  }
  return result.data as TEntity;
};
