import { z } from 'zod';
import { fromISODate } from '../utils/date.utils';

export const AccountInformationRawSchema = z.object({
  service: z.object({
    names: z.array(z.string()),
    details: z.object({
      serviceStartDate: z.string(),
      contractRenewalDate: z.string(),
    }),
  }),
  products: z.array(z.string()),
});

export const AccountInformationSchema = AccountInformationRawSchema.transform((data) => ({
  ...data,
  service: {
    ...data.service,
    details: {
      ...data.service.details,
      serviceStartDate: fromISODate(data.service.details.serviceStartDate),
      contractRenewalDate: fromISODate(data.service.details.contractRenewalDate),
    },
  },
}));

export type AccountInformation = z.infer<typeof AccountInformationSchema>;
