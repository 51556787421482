import {
  useQueryClient,
  useQuery,
  useMutation,
  UseQueryReturnType,
  UseMutationReturnType,
  UseQueryOptions,
} from '@tanstack/vue-query';
import { useImpersonationInfo } from './useImpersonationInfo';
import { watch } from 'vue';
import { push } from 'notivue';

export interface UseQueryConfig<QueryType, MutationType> {
  queryKey: string[];
  queryFn: () => Promise<QueryType>;
  mutationFn?: () => Promise<MutationType>;
  queryOpts?: Omit<UseQueryOptions<QueryType, unknown>, 'queryKey' | 'queryFn'>;
  errorMsg?: string;
}
export const useMutableQuery = <QueryType, MutationType>({
  queryKey,
  queryFn,
  mutationFn,
  queryOpts,
  errorMsg,
}: UseQueryConfig<QueryType, MutationType>): [
  UseQueryReturnType<QueryType, unknown>,
  UseMutationReturnType<MutationType, unknown, void, unknown> | undefined,
] => {
  const impersonationInfo = useImpersonationInfo();
  const modifiedQueryKey = impersonationInfo?.impersonatedUser?.id
    ? // Append impersonated user id to query key
      //  to make sure the query will be refetched when impersonated user changes
      [...queryKey, impersonationInfo.impersonatedUser.id]
    : queryKey;
  const queryResult = useQuery({
    queryKey: modifiedQueryKey,
    queryFn,
    refetchOnWindowFocus: false,
    ...queryOpts,
  });

  // Mutation
  const queryClient = useQueryClient();
  const mutation = mutationFn
    ? useMutation({
        mutationFn,
        onSuccess: () => {
          // Invalidate and refetch
          queryClient.invalidateQueries({ queryKey });
        },
      })
    : undefined;

  watch(
    () => queryResult.isError.value,
    () => {
      if (queryResult.isError.value) {
        push.error({
          message: errorMsg || 'An error occurred while fetching the necessary data. Please try again later.',
          duration: Infinity,
        });
      }
    },
  );

  return [queryResult, mutation];
};
