import transform from 'lodash/transform';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isObject from 'lodash/isObject';

const camelize = (obj): string =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export const toCamelCase = <T>(obj: object): T => {
  return camelize(obj) as T;
};

const snakeize = (obj): string =>
  transform(obj, (acc, value, key, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);
    acc[snakeKey] = isObject(value) ? snakeize(value) : value;
  });
export const toSnakeCase = <T>(obj: object): T => {
  return snakeize(obj) as T;
};

export const combineArraysToObjects = (keys: string[], values: string[]): Record<string, string>[] => {
  return values.map((row) => Object.fromEntries(keys.map((key, i) => [key, row[i]])));
};

export const removeWhitespaceFromKeys = (arr: string[]): string[] => {
  return arr.map((el) => el.replace(/\s/g, ''));
};

export const strToBoolean = (value: string): boolean => {
  const checkValue = value.toLowerCase();
  return checkValue === 'true' || checkValue === 'yes' || checkValue === '1';
};
