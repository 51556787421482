import { Tenant } from '@/entities/tenant.entity';
import { defineStore } from 'pinia';

type AppState = {
  tenant: Tenant;
};

export const useAdminTenantStore = defineStore('admin-tenant-store', {
  state: (): AppState => ({
    tenant: undefined,
  }),
  actions: {
    setTenant(tenant: Tenant) {
      this.tenant = tenant;
    },
    clearTenant() {
      this.tenant = undefined;
    },
  },
});
