import { AuthUser } from '@/entities/authUser.entity';
import { MitreFilter } from '@/entities/mitreFilters.entity';
import { AuditLogPeriod } from '@/entities/auditLogPeriods.entity';
import { DashboardPeriod } from '@/entities/dashboardPeriods.entity';
import { ImpersonationStore } from '@/stores/impersonation.store.types';
import { StorageType, createStorageHandler } from '@/utils/storage.utils';

export const authUserStorage = createStorageHandler<AuthUser>('authUser', StorageType.LocalStorage);

export const dashboardPeriodStorage = createStorageHandler<DashboardPeriod>(
  'dashboardPeriod',
  StorageType.SessionStorage,
);

export const auditLogPeriodStorage = createStorageHandler<AuditLogPeriod>('auditLogPeriod', StorageType.SessionStorage);

export const mitreFilterStorage = createStorageHandler<MitreFilter>('mitreFilters', StorageType.SessionStorage);

export const impersonationStorage = createStorageHandler<ImpersonationStore>('impersonation', StorageType.LocalStorage);
