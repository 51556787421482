<template>
  <PopoverButton :id="idRef" :dropdown-options="dropdownOptions" :value="value" :items="displayedItems">
    <template #button="{ buttonId, selectedItem }">
      <div :id="`${buttonId}-button`" ref="triggerElRef">
        <Button :class="`select-button ${$attrs.class}`" :style="{ display: !isMenuShowRef ? 'flex' : 'none' }">
          <div class="max-w-48 truncate sm:max-w-full text-text">
            {{ selectedItem?.label ?? placeholder }}
          </div>
          <template #end-adornment>
            <SpiningCircleIcon v-if="loading" class="w-6 h-6 text-text min-w-6" />
            <ChevronDownIcon v-else class="w-6 h-6 text-text min-w-6" />
            <CloseIcon
              v-if="canClearValue && value"
              class="w-6 h-6 text-text-support min-w-6"
              view-box="-8 -6 36 36"
              @click="handleCloseClick"
            />
          </template>
        </Button>
        <div ref="inputWrapperElRef" class="select-button" :style="{ display: isMenuShowRef ? 'flex' : 'none' }">
          <input
            :id="`${idRef}-input`"
            ref="inputElRef"
            type="text"
            :placeholder="selectedItem?.label"
            @click="handleInputClick"
            @keyup="handleInputChange"
          />
          <div class="flex items-center gap-4">
            <SpiningCircleIcon v-if="loading" class="w-6 h-6 text-text min-w-6" />
            <ChevronDownIcon v-else class="w-6 h-6 text-text min-w-6" />
            <CloseIcon
              v-if="canClearValue && value"
              class="w-6 h-6 text-text-support min-w-6 cursor-pointer"
              view-box="-8 -6 36 36"
              @click="handleCloseClick"
            />
          </div>
        </div>
      </div>
    </template>
  </PopoverButton>
</template>
<script setup lang="ts">
import { AnyPrimitive } from '@/entities/common';
import PopoverButton from './PopoverButton.vue';
import { DropdownOptions } from 'flowbite';
import Button from './Button.vue';
import { computed, nextTick, onBeforeMount, onMounted, ref } from 'vue';
import ChevronDownIcon from './icons/ChevronDownIcon.vue';
import debounce from 'lodash/debounce';
import { DEBOUNCE_TIME } from '@/app-constants/layout.constant';
import { nanoid } from 'nanoid';
import { PopoverItem } from './popoverButton.type';
import CloseIcon from '@/components/icons/CloseIcon.vue';
import SpiningCircleIcon from './icons/SpiningCircleIcon.vue';

const idRef = ref<string>();
const triggerElRef = ref<HTMLElement | null>(null);
const inputElRef = ref<HTMLInputElement | null>(null);
const inputWrapperElRef = ref<HTMLDivElement | null>(null);
const isMenuShowRef = ref<boolean>(false);
const searchStringRef = ref<string>('');

const props = withDefaults(
  defineProps<{
    id?: string;
    items?: PopoverItem[];
    value?: AnyPrimitive;
    placeholder?: string;
    loading?: boolean;
    canClearValue?: boolean;
    dropdownOptions?: DropdownOptions;
  }>(),
  {
    id: undefined,
    value: undefined,
    items: undefined,
    placeholder: 'Select',
    canClearValue: false,
    dropdownOptions: (): DropdownOptions => ({
      placement: 'bottom-start',
      onShow: ({ _triggerEl }): void => {
        // Dispatch a custom event to the trigger element
        _triggerEl.dispatchEvent(new CustomEvent('dropdown:show'));
      },
      onHide: ({ _triggerEl }): void => {
        // Dispatch a custom event to the trigger element
        _triggerEl.dispatchEvent(new CustomEvent('dropdown:hide'));
      },
    }),
  },
);

const displayedItems = computed(() => {
  if (!props.items) return [];
  if (!searchStringRef.value) return props.items;
  return props.items?.filter(
    (item) =>
      item.label.toLowerCase().includes(searchStringRef.value.toLowerCase()) ||
      item.value.toString().toLowerCase().includes(searchStringRef.value.toLowerCase()),
  );
});

const emits = defineEmits(['clear-value']);

const handleCloseClick = (e: MouseEvent): void => {
  e.stopPropagation();
  emits('clear-value');
};

const handleInputClick = (e: MouseEvent): void => {
  e.stopPropagation();
};

const handleInputChange = debounce((e: Event): void => {
  searchStringRef.value = (e.target as HTMLInputElement).value;
}, DEBOUNCE_TIME);

const calculateMenuWidth = (): void => {
  const menuEl = document.getElementById(idRef.value);
  if (!menuEl || !triggerElRef.value) {
    return;
  }
  const triggerElWidth = triggerElRef.value.getBoundingClientRect().width;
  menuEl.style.width = `${triggerElWidth}px`;
};

const handleMenuShow = async (): Promise<void> => {
  isMenuShowRef.value = true;
  calculateMenuWidth();
  await nextTick();
  inputElRef.value?.focus();
};
const handleMenuHide = (): void => {
  isMenuShowRef.value = false;
  inputElRef.value.value = '';
  searchStringRef.value = '';
};

onBeforeMount(() => {
  idRef.value = props.id ?? nanoid();
});

onMounted(() => {
  if (triggerElRef.value) {
    triggerElRef.value.addEventListener('dropdown:show', handleMenuShow);
    triggerElRef.value.addEventListener('dropdown:hide', handleMenuHide);
  }
});
</script>
<style scoped>
.select-button {
  @apply bg-gray-700 border-gray-300 rounded-lg;
  @apply px-4 py-3.5;
  @apply flex gap-4;
  @apply w-full;
  @apply max-w-full;
  @apply text-left;
  @apply text-text-support;
}
.select-button:deep(input) {
  @apply grow;
  @apply bg-transparent;
  @apply border-0 outline-0 ring-0;
  @apply p-0 m-0;
  /* set place holder color */
  @apply placeholder-text-support;
}
.select-button:deep(input:focus) {
  @apply border-0 outline-0 ring-0;
}
</style>
