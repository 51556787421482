// eslint-disable-next-line @typescript-eslint/no-var-requires
import flowbite from 'flowbite/plugin';
import svgToDataUri from 'mini-svg-data-uri';

flowbite.chart = true;
const colors = {
  primary: {
    50: '#e6ecf8',
    100: '#b0c4e8',
    200: '#8aa8dd',
    300: '#5480cd',
    400: '#3368c4',
    500: '#0042b5',
    600: '#003ca5',
    700: '#002f81',
    800: '#002464',
    900: '#001c4c',
  },
  gray: {
    50: '#e9eaec',
    100: '#babdc4',
    200: '#999da8',
    300: '#6b7180',
    400: '#4e5568',
    500: '#222b42',
    600: '#1f273c',
    700: '#181f2f',
    800: '#131824',
    900: '#0e121c',
  },
};

/** @type {import('tailwindcss').Config} */
export default {
  darkMode: 'class',
  content: [
    './index.html',
    './src/**/*.{vue,js,ts,jsx,tsx}',
    './node_modules/flowbite/**/*.js',
    './node_modules/flowbite-vue/**/*.{js,jsx,ts,tsx,vue}',
  ],
  theme: {
    extend: {
      fontFamily: {
        public: ['Public', 'sans-serif'],
      },
      screens: {
        xs: '480px',
        xxs: '380px',
      },
      backgroundImage: (theme) => ({
        'multiselect-caret': `url("${svgToDataUri(
          `<svg viewBox="0 0 320 512" fill="${theme('colors.gray.50')}" xmlns="http://www.w3.org/2000/svg"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>`,
        )}")`,
        'multiselect-spinner': `url("${svgToDataUri(
          `<svg viewBox="0 0 512 512" fill="${theme('colors.green.500')}" xmlns="http://www.w3.org/2000/svg"><path d="M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z"></path></svg>`,
        )}")`,
        'multiselect-remove': `url("${svgToDataUri(
          `<svg viewBox="0 0 320 512" fill="${theme('colors.gray.50')}" xmlns="http://www.w3.org/2000/svg"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>`,
        )}")`,
      }),
      colors: {
        gray: colors.gray,
        primary: colors.primary,
        black: '#000000',
        text: colors.gray['50'], // Use for primary text, such as body copy, sentence case headers
        'text-primary': '#5480CD',
        'text-inverse': '#131824',
        'text-support': colors.gray['200'], // Use for secondary text, such as navigation, input field labels, and supporting text
        'text-disabled': colors.gray['300'], // Use for secondary text, such as navigation, input field labels, and supporting text
        error: '#F98080', // Use for error text
        icon: colors.gray['50'], // Use for icon in general, icons paired with text
        'icon-support': colors.gray['200'], // Use for icons paired with support text
        'icon-success': '#31C48D',
        'icon-error': '#F98080',
        'icon-disabled': '#6B7180',
        background: colors.gray['900'], // Use as the primary background for the UI
        container: colors.gray['500'], // Use as background for cards, etc.
        'container-low-emphasis': colors.gray['700'], // Use as background for cards, etc.
      },
    },
  },
  plugins: [flowbite],
};
