<template>
  <ul class="app-sidebar-menu">
    <li v-for="item in displayItems" :key="item.id" class="app-sidebar-menu-list">
      <a
        :href="sanitizeUrl(item.link)"
        :class="{
          'app-sidebar-menu-list-item': true,
          group: true,
          active: item.active,
        }"
        :data-drawer-hide="item.link ? sideMenuId : undefined"
        @click.prevent="($event) => handleItemClick(item, $event)"
      >
        <component :is="item.icon" v-if="item.icon" class="app-sidebar-menu-list-icon" />
        <div class="grow flex justify-between items-center">
          <span class="ms-3">{{ item.name }}</span>
          <svg
            v-if="item.children"
            class="w-3 h-3 dark:text-icon dark:group-hover:text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </div>
      </a>
      <ul :class="`${item.expanded ? '' : 'hidden'}`">
        <li v-for="subItem in item.children" :key="subItem.id">
          <a
            :href="sanitizeUrl(subItem.link)"
            :data-drawer-hide="`${subItem.link ? sideMenuId : ''}`"
            :class="{
              'app-sidebar-menu-dropdown-list-item': true,
              group: true,
              active: subItem.active,
            }"
            @click.prevent="($event) => handleItemClick(subItem, $event)"
            >{{ subItem.name }}</a
          >
        </li>
      </ul>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { sanitizeUrl } from '@braintree/sanitize-url';
import { useRouter } from 'vue-router';
import { SideMenuItem } from '@/entities/sideMenuItem.entity';
import { ref, watchEffect } from 'vue';

const props = defineProps<{
  items: SideMenuItem[];
  sideMenuId: string;
}>();

const router = useRouter();

const displayItems = ref<SideMenuItem[]>(props.items);
const setActiveItem = (items: SideMenuItem[], path: string): boolean => {
  let hasActiveItem = false;
  items.forEach((item) => {
    if (item.link === path) {
      item.active = true;
      hasActiveItem = true;
    } else {
      item.active = false;
    }
    if (item.children) {
      const hasActiveChild = setActiveItem(item.children, path);
      if (hasActiveChild) {
        item.expanded = true;
      }
    }
  });
  return hasActiveItem;
};
watchEffect(() => {
  displayItems.value = props.items;
  setActiveItem(displayItems.value, router.currentRoute.value.path);
});

// define the emit function
const emit = defineEmits(['onItemClick']);
const handleItemClick = (item: SideMenuItem, $event: MouseEvent): void => {
  $event.preventDefault();
  if (item.link) {
    router.push({ path: item.link });
  }
  emit('onItemClick', item);
};
</script>
