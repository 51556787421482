export enum StorageType {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
}

export type StorageHandler<T> = {
  get: () => T;
  set: (value: T) => void;
  remove: () => void;
};

export const createStorageHandler = <T>(name: string, type: StorageType): StorageHandler<T> => {
  const storage = type === StorageType.LocalStorage ? localStorage : sessionStorage;

  return {
    get: (): T => {
      const item = storage.getItem(name);
      return item ? JSON.parse(item) : null;
    },
    set: (value: T): void => {
      storage.setItem(name, JSON.stringify(value));
    },
    remove: (): void => {
      storage.removeItem(name);
    },
  };
};
