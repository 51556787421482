// Level of role:
// System Admin > Digital Hands Admin > Digital Hands CSM > Customer Admin > Customer User.
// The smaller number, the higher level
export const PREDEFINED_ROLES = {
  SYSTEM_ADMIN: {
    id: 1,
    roleName: 'System Admin',
    roleDescription: 'System Admin',
    level: 1,
  },
  DH_ADMIN: {
    id: 2,
    roleName: 'Digital Hands Admin',
    roleDescription: 'Digital Hands Administrator',
    level: 2,
  },
  CUSTOMER: {
    id: 3,
    roleName: 'Customer User',
    roleDescription: 'Customer',
    level: 5,
  },
  CUSTOMER_ADMIN: {
    id: 4,
    roleName: 'Customer Admin',
    roleDescription: 'Customer Admin',
    level: 4,
  },
  CUSTOMER_SUCCESS_MANAGER: {
    id: 5,
    roleName: 'Customer Success Manager',
    roleDescription: 'Digital Hands Customer Success Manager',
    level: 3,
  },
};
