<template>
  <slot name="button" :button-id="idRef" :selected-item="selectedItem"></slot>
  <!-- Dropdown menu -->
  <Teleport to="body">
    <div :id="idRef" class="dropdown-menu w-44 hidden text-sm">
      <ul v-if="items?.length" aria-labelledby="dropdownDefaultButton" class="dropdown-menu-list">
        <li
          v-for="item in items"
          :key="item.label"
          class="dropdown-menu-item"
          :class="{ active: item.value === selectedItem?.value }"
          @click="handleItemClick(item.value)"
        >
          {{ item.label }}
          <CheckIcon v-if="item.value === selectedItem?.value" class="w-4 h-4 text-green-400" />
        </li>
      </ul>
      <p v-else class="dropdown-menu-item">No options available</p>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { AnyPrimitive } from '@/entities/common';
import { Dropdown, DropdownInterface, DropdownOptions } from 'flowbite';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { nanoid } from 'nanoid';
import { PopoverItem } from './popoverButton.type';
import CheckIcon from './icons/CheckIcon.vue';

const props = withDefaults(
  defineProps<{
    id?: string;
    items?: PopoverItem[];
    value?: AnyPrimitive;
    dropdownOptions?: DropdownOptions;
  }>(),
  {
    id: undefined,
    value: undefined,
    items: undefined,
    dropdownOptions: (): DropdownOptions => ({
      placement: 'bottom-start',
    }),
  },
);

const idRef = ref<string>();
const dropdownRef = ref<DropdownInterface | null>(null);

const emits = defineEmits(['change']);
const handleItemClick = (newValue: AnyPrimitive): void => {
  emits('change', newValue);
  dropdownRef.value.hide();
};

const selectedItem = computed(() => {
  return props.items?.find((item) => item.value === props.value);
});

onBeforeMount(() => {
  idRef.value = props.id ?? nanoid();
});

onMounted(() => {
  // get the dropdown menu element
  const $targetEl: HTMLElement = document.getElementById(idRef.value);
  // get the element that trigger the dropdown menu on click
  const $triggerEl: HTMLElement = document.getElementById(`${idRef.value}-button`);
  dropdownRef.value = new Dropdown($targetEl, $triggerEl, props.dropdownOptions, {
    id: idRef.value,
  });
});
</script>
<style scoped>
.dropdown-menu {
  max-height: 60vh;
  overflow: auto;
}

.dropdown-menu-item.active {
  @apply bg-primary-500;
  @apply text-white font-bold;
}
</style>
