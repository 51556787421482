<script>
export default {
  name: 'AuthenticatingLayout',
};
</script>
<script setup lang="ts"></script>

<template>
  <main class="relative h-full">
    <div class="h-full p-4 xl:pt-5 bg-background dark:border-gray-700 dark:text-gray-50">
      <RouterView />
    </div>
  </main>
</template>
