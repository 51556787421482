import { getDeviceType } from './device.utils';

export const isParticalElementInViewport = (
  el: HTMLElement,
  offset: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  } = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
): boolean => {
  const rect = el.getBoundingClientRect();
  // check if the (top is smaller than the screen middle
  // or the bottom is bigger than the screen middle)
  // aware of input offset
  const rectTop = rect.top + offset.top;
  const rectBottom = rect.bottom - offset.bottom;
  const rectLeft = rect.left + offset.left;
  const rectRight = rect.right - offset.right;
  return (
    (((rectTop < window.innerHeight / 2 && rectTop >= 0) ||
      (rectBottom > window.innerHeight / 2 && rectBottom <= window.innerHeight) ||
      (rectTop < 0 && rectBottom > window.innerHeight)) &&
      ((rectLeft < window.innerWidth / 2 && rectLeft >= 0) ||
        (rectRight > window.innerWidth / 2 && rectRight <= window.innerWidth))) ||
    (rectLeft < 0 && rectRight > window.innerWidth)
  );
};

export const getOffsetTop = (element: HTMLElement): number => {
  return element ? element.offsetTop + getOffsetTop(element.offsetParent as HTMLElement) : 0;
};

export const isBreakpoint = (breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl'): boolean => {
  const breakpoints = {
    sm: window.innerWidth >= 640,
    md: window.innerWidth >= 768,
    lg: window.innerWidth >= 1024,
    xl: window.innerWidth >= 1280,
    '2xl': window.innerWidth >= 1536,
  };
  return breakpoints[breakpoint];
};

export function downloadFile(url: string, filename: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';

  const deviceType = getDeviceType();
  if (deviceType.isDesktop) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function getInnerHeight(el: HTMLElement): number {
  const computed = getComputedStyle(el);
  const padding = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);

  return el.clientHeight - padding;
}
