const serviceBus = {};

export const subscribeAccessTokenChanged = (topic: string, callback: (data: unknown) => void): void => {
  if (!serviceBus[topic]) {
    serviceBus[topic] = [];
  }
  serviceBus[topic].push(callback);
};

export const unsubscribeAccessTokenChanged = (topic: string): void => {
  if (serviceBus[topic]) {
    serviceBus[topic] = [];
  }
};

export const publishAccessTokenChanged = (topic: string, data: unknown): void => {
  if (serviceBus[topic]) {
    serviceBus[topic].forEach((callback) => {
      callback(data);
    });
  }
};
