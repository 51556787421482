import { NotivueTheme, slateTheme } from "notivue";
import { Config } from "tailwindcss";
export function configNVTheme(theme: Config): NotivueTheme {
  const nvTheme: NotivueTheme = {
    ...slateTheme,
    '--nv-global-bg': theme.colors['text'],
    '--nv-global-fg': theme.colors.black,
    '--nv-radius': '8px',
    '--nv-spacing': '14px',
    '--nv-message-size': '14px',
    '--nv-icon-size': '1rem',
  };
  return nvTheme;
}