<template>
  <aside id="offcanvas-sidemenu" class="app-sidebar -translate-x-full xl:translate-x-0" aria-label="Sidebar">
    <div id="logo-cont" class="app-sidebar-logo-container">
      <RouterLink :to="ROUTER.ADMIN.HOME_PAGE.PATH">
        <a href="javascript:void(0);" data-drawer-hide="offcanvas-sidemenu">
          <img src="../../assets/branch-logo.svg" class="app-sidebar-logo" alt="Digital Hands Logo" />
        </a>
      </RouterLink>
    </div>
    <div id="menu-cont" class="app-sidebar-menu-container">
      <SideMenu side-menu-id="offcanvas-sidemenu" :items="menuItems" @on-item-click="handleItemClick" />
    </div>
  </aside>
</template>

<script setup lang="ts">
import { markRaw, onMounted, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { initFlowbite } from 'flowbite';

import HomeIcon from '@/components/icons/HomeIcon.vue';
import FileIcon from '@/components/icons/FileIcon.vue';
import UsersIcon from '@/components/icons/UsersIcon.vue';

import { useAuthStore } from '@/stores/auth.store';

import { ROUTER } from '../../app-constants/router.constant';
import { SideMenuItem } from '@/entities/sideMenuItem.entity';
import SideMenu from './SideMenu.vue';

import { IsCustomerSuccessManager } from '@/utils/user.utils';

const { getUser } = useAuthStore();
const authUser = getUser();

const isCSM = IsCustomerSuccessManager(authUser);
const menuItems = ref<SideMenuItem[]>([]);

const buildSideMenuItems = (): void => {
  // Homepage
  menuItems.value.push({
    id: ROUTER.ADMIN.HOME_PAGE.KEY,
    name: ROUTER.ADMIN.HOME_PAGE.NAME,
    link: ROUTER.ADMIN.HOME_PAGE.PATH,
    icon: markRaw(HomeIcon),
  });
  // File Center
  menuItems.value.push({
    id: ROUTER.ADMIN.FILES_PAGE.KEY,
    name: ROUTER.ADMIN.FILES_PAGE.NAME,
    link: ROUTER.ADMIN.FILES_PAGE.PATH,
    icon: markRaw(FileIcon),
  });

  if (!isCSM) {
    // Internal Administrator
    menuItems.value.push({
      id: ROUTER.ADMIN.CSM_PAGE.KEY,
      name: ROUTER.ADMIN.CSM_PAGE.NAME,
      link: ROUTER.ADMIN.CSM_PAGE.PATH,
      icon: markRaw(UsersIcon),
    });
  }
};

const handleItemClick = (item: SideMenuItem): void => {
  const itemFromList = menuItems.value.find((menuItem) => menuItem.id === item.id);
  itemFromList?.children?.length && (itemFromList.expanded = !itemFromList.expanded);
};

onMounted(() => {
  buildSideMenuItems();
  initFlowbite();
});
</script>
