import { TenantSchema } from './tenant.entity';
import { RoleSchema } from './role.entity';
import { z } from 'zod';
import { fromISODate } from '@/utils/date.utils';

export enum UserStatus {
  active = 1,
  inactive = 2,
  deleted = 3,
}

export const UserBaseSchema = z.object({
  id: z.string(),
  email: z.string(),
  auth0Id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  fullName: z.string().optional().nullable(),
  status: z.number(),
  snowContactId: z.string().optional().nullable(),
  tenantId: z.string().optional().nullable(),
  isSecurityPostureSurveyEnabled: z.boolean().optional().nullable(),
  createdAt: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => fromISODate(val)),
  modifiedAt: z
    .string()
    .optional()
    .nullable()
    .transform((val: string) => fromISODate(val)),
  lastLoginAt: z.string().optional().nullable(),
});

export const AuthUserRawSchema = UserBaseSchema.extend({
  tenant: TenantSchema.optional().nullable(),
  roles: z.array(RoleSchema).optional().nullable(),
  accessToken: z.string().optional().nullable(),
});

export const AuthUserSchema = AuthUserRawSchema.transform((data) => ({
  ...data,
  status: data.status as UserStatus,
}));

export type AuthUser = z.infer<typeof AuthUserSchema>;
export type UserBase = z.infer<typeof UserBaseSchema>;

export const ListOfAuthUserSchema = z.array(AuthUserSchema);
export type ListOfAuthUser = z.infer<typeof ListOfAuthUserSchema>;
