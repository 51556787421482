import snakeCase from 'lodash/snakeCase';

import { CUSTOMER_SERVICE_URL } from '@/app-constants/env.constant';

import { ListOfAuthUser, ListOfAuthUserSchema } from '@/entities/authUser.entity';
import { PaginationDto, PaginationInfo, PaginationInfoSchema } from '@/entities/paginationInfo.entity';

import { objectToEntity } from '@/utils/transform.utils';
import { buildQueryString, fetchJsonWithAuthorization } from '@/utils/fetch.utils';
import { ManagedCustomers, ManagedCustomersSchema } from '@/entities/csm.entity';
import { LoginMetrics, LoginMetricsSchema } from '@/entities/loginMetrics.entity';
import { Tenants, TenantsSchema } from '@/entities/tenant.entity';

const ROOT_URL = `${CUSTOMER_SERVICE_URL}/api`;

export const getUsersOfCSM = async (
  showInactiveUsers: boolean,
  { page, limit, sortOrder, sortBy }: PaginationDto,
): Promise<[ListOfAuthUser, PaginationInfo]> => {
  const query = buildQueryString({
    show_inactive_users: showInactiveUsers,
    page: page.toString(),
    page_size: limit.toString(),
    sort_order: sortOrder,
    sort_by: snakeCase(sortBy),
    role_key: 5,
  });
  const url = `${ROOT_URL}/admin/users?${query}`;
  const jsonObj: { items: [] } = await fetchJsonWithAuthorization(url);
  const { items, ...pagination } = jsonObj;
  const users = objectToEntity<ListOfAuthUser>(ListOfAuthUserSchema, items);
  const paginationInfo = objectToEntity<PaginationInfo>(PaginationInfoSchema, pagination);
  return [users, paginationInfo];
};

export const getManagedCustomers = async (
  userId: string,
  { page, limit, sortOrder, sortBy }: PaginationDto,
): Promise<[ManagedCustomers, PaginationInfo]> => {
  const query = buildQueryString({
    page: page.toString(),
    page_size: limit.toString(),
    sort_order: sortOrder,
    sort_by: sortBy,
  });
  const url = `${ROOT_URL}/admin/csm/${userId}/tenants?${query}`;
  const jsonObj: { items: [] } = await fetchJsonWithAuthorization(url);
  const { items, ...pagination } = jsonObj;
  const customers = objectToEntity<ManagedCustomers>(ManagedCustomersSchema, items);
  const paginationInfo = objectToEntity<PaginationInfo>(PaginationInfoSchema, pagination);
  return [customers, paginationInfo];
};

export const createManagedCustomerAccess = async (userId: string, data: string): Promise<void> => {
  const url = `${ROOT_URL}/admin/csm/${userId}/tenants`;
  const options = {
    method: 'POST',
    body: data,
  };
  await fetchJsonWithAuthorization(url, options, false);
};

export const deleteCustomerAccess = async (userId: string, tenantId: string): Promise<void> => {
  const url = `${ROOT_URL}/admin/csm/${userId}/tenants/${tenantId}`;
  const options = {
    method: 'DELETE',
  };
  await fetchJsonWithAuthorization(url, options, false);
};

export const getCSMLoginMetrics = async (tenantId?: string): Promise<LoginMetrics> => {
  const query = tenantId ? `?tenant_id=${tenantId}` : '';
  const url = `${ROOT_URL}/csm/metrics/login-metrics${query}`;

  const jsonObj = await fetchJsonWithAuthorization(url);

  return objectToEntity<LoginMetrics>(LoginMetricsSchema, jsonObj);
};

export const getCSMTenants = async (): Promise<Tenants> => {
  const url = `${ROOT_URL}/csm/tenants`;

  const jsonObj = await fetchJsonWithAuthorization(url);

  return objectToEntity<Tenants>(TenantsSchema, jsonObj);
};

export const getAllCSMUsers = async (): Promise<ListOfAuthUser> => {
  const url = `${ROOT_URL}/admin/csm/users`;
  const jsonObj = await fetchJsonWithAuthorization(url);

  return objectToEntity<ListOfAuthUser>(ListOfAuthUserSchema, jsonObj);
};

export const transferCustomerAccess = async (data: string): Promise<void> => {
  const url = `${ROOT_URL}/admin/csm/tenant-transfers`;
  const options = {
    method: 'PUT',
    body: data,
  };
  await fetchJsonWithAuthorization(url, options, false);
};
