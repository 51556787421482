import { z } from 'zod';

export const SecurityPostureScoreSchema = z.object({
  tenantId: z.string(),
  value: z.number(),
});

export const SurveyScoreSchema = z.object({
  value: z.number(),
});

export const ServiceScoreSchema = z.object({
  serviceName: z.string(),
  defaultValue: z.number(),
  displayValue: z.number(),
});

export const ServiceScoresSchema = z.array(ServiceScoreSchema);

export const SurveyScoreDetailSchema = z.object({
  surveyScore: SurveyScoreSchema,
  serviceScores: ServiceScoresSchema,
});

export type ServiceScore = z.infer<typeof ServiceScoreSchema>;
export type ServiceScores = z.infer<typeof ServiceScoresSchema>;
export type SurveyScoreDetail = z.infer<typeof SurveyScoreDetailSchema>;
