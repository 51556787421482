import { ComputedRef, Ref, computed } from 'vue';
import { useMutableQuery } from './useMutableQuery';

import { getAccountInformation } from '@/services/servicesInfo.service';

export type AccountInformation = {
  products: ComputedRef<string[]>;
  hasProductChronicle: ComputedRef<boolean>;
  hasProductSentinelOne: ComputedRef<boolean>;
  hasProductServiceNow: ComputedRef<boolean>;
  isLoading: Ref<boolean>;
  isFetching: Ref<boolean>;
  isSuccess: Ref<boolean>;
};

export const useAccountInformation = (): AccountInformation => {
  const [{ data, isLoading, isFetching, isSuccess }] = useMutableQuery({
    queryKey: ['getAccountInformation'],
    queryFn: async () => getAccountInformation(),
    queryOpts: {
      staleTime: Infinity, // This will make the data never go stale
    },
  });

  const products = computed(() => {
    if (!data.value) return [];

    return data.value.products;
  });

  const hasProductChronicle = computed(() => {
    return data.value?.products?.includes('chronicle');
  });

  const hasProductSentinelOne = computed(() => {
    return data.value?.products?.includes('sentinel-one');
  });

  const hasProductServiceNow = computed(() => {
    return data.value?.products?.includes('servicenow');
  });

  return {
    products,
    hasProductChronicle,
    hasProductSentinelOne,
    hasProductServiceNow,
    isLoading,
    isFetching,
    isSuccess,
  };
};
