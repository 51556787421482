import { fromISODate } from '@/utils/date.utils';
import { z } from 'zod';

export const LoginMetricsSchema = z.object({
  totalTenantCount: z.number(),
  totalUserCount: z.number(),
  lastLoginAt: z
    .string()
    .nullable()
    .optional()
    .transform((val) => fromISODate(val)),
  last24HoursLoginUserCount: z.number(),
  threeMonthAvgLoginUserCount: z.number(),
});

export type LoginMetrics = z.infer<typeof LoginMetricsSchema>;
