<template>
  <div :class="`${item.isRead ? '' : 'bg-container'} notification-item`">
    <h5 class="text-text text-sm sm:text-base font-semibold">{{ item?.subject }}</h5>
    <ExpandableBox :max-height="maxHeight" :expanded="isExpanded" @on-expanded-change="handleExpandedChange">
      <MarkdownText class="text-text text-sm sm:text-base font-normal" :markdown="item?.message" />
    </ExpandableBox>
    <p class="text-text-support text-xs sm:text-sm font-normal">{{ formatRelativeDate(item?.createdAt) }}</p>
  </div>
</template>

<script setup lang="ts">
import { Notification } from '@/entities/notification.entity';
import { formatRelativeDate } from '@/utils/date.utils';
import MarkdownText from '../MarkdownText.vue';
import ExpandableBox from '../ExpandableBox.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { isBreakpoint } from '@/utils/html.utils';

const MOBILE_LINE_HEIGHT = 20; // 20px ~ 1.25rem
const DESKTOP_LINE_HEIGHT = 24; // 24px ~ 1.5rem
const MAX_LINES = 2;
const maxHeight = ref(MOBILE_LINE_HEIGHT * MAX_LINES);

withDefaults(
  defineProps<{
    item?: Notification;
    isExpanded?: boolean;
  }>(),
  {
    item: (): Notification => undefined,
    isExpanded: false,
  },
);

const emits = defineEmits(['onExpandedChange']);
const handleExpandedChange = (isExpanded: boolean): void => {
  emits('onExpandedChange', isExpanded);
};

const handleWindowResize = (): void => {
  maxHeight.value = (isBreakpoint('sm') ? DESKTOP_LINE_HEIGHT : MOBILE_LINE_HEIGHT) * MAX_LINES;
};
onMounted(() => {
  handleWindowResize();
  window.addEventListener('resize', handleWindowResize);
});
onUnmounted(() => {
  window.removeEventListener('resize', handleWindowResize);
});
</script>
<style scoped>
.notification-item {
  @apply p-4 flex flex-col gap-1.5;
}
.notification-item:deep(a) {
  @apply text-primary-300 underline;
}
</style>
