<script lang="ts">
export default {
  name: 'CustomerLayout',
};
</script>
<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth.store';

import AppNavBar from '@/components/app-layouts/AppNavBar.vue';
import AdminSideBar from '@/components/app-layouts/AdminSideBar.vue';
import AppFooter from '@/components/app-layouts/AppFooter.vue';
import LoadingPanel from '../LoadingPanel.vue';

import { ROUTER } from '@/app-constants/router.constant';
import { isAdministrator, IsCustomerSuccessManager } from '@/utils/user.utils';
import { publishAccessTokenChanged } from '@/utils/event.utils';

const { getAccessToken } = useAuthStore();

const router = useRouter();

const { hasAuthenticated, getUser } = useAuthStore();
const isAuthenticated = hasAuthenticated();
const authUser = getUser();
const isAdmin = isAdministrator(authUser) || IsCustomerSuccessManager(authUser);

onMounted(() => {
  const token = getAccessToken();
  publishAccessTokenChanged('access-token-changed', token);

  if (!isAdmin) {
    router.push({ path: ROUTER.CUSTOMER.HOME_PAGE.PATH, replace: true });
  }
});
</script>

<template>
  <template v-if="!isAuthenticated || !isAdmin">
    <main class="relative h-full">
      <div class="h-full p-4">
        <LoadingPanel />
      </div>
    </main>
  </template>
  <template v-else>
    <div class="flex flex-col min-h-full">
      <AppNavBar :show-tenant-name="false" />
      <AdminSideBar />
      <main class="relative xl:ml-64 grow flex flex-col justify-between">
        <div class="p-4 xl:pl-5 xl:pr-8">
          <RouterView />
        </div>
        <AppFooter />
      </main>
    </div>
  </template>
</template>
