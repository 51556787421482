import CustomerLayout from '@/components/app-layouts/CustomerLayout.vue';
import AuthenticatingLayout from '@/components/app-layouts/AuthenticatingLayout.vue';
import AdminLayout from '@/components/app-layouts/AdminLayout.vue';
import AdminTenantLayout from '@/components/app-layouts/AdminTenantLayout.vue';
import SignupLayout from '@/components/app-layouts/SignupLayout.vue';

export const DEBOUNCE_TIME = 300;

export const LAYOUTS = {
  CUSTOMER: {
    name: 'CUSTOMER',
    component: CustomerLayout,
  },
  AUTHENTICATING: {
    name: 'AUTHENTICATING',
    component: AuthenticatingLayout,
  },
  SIGNUP: {
    name: 'SIGNUP',
    component: SignupLayout,
  },
  ADMIN: {
    name: 'ADMIN',
    component: AdminLayout,
  },
  ADMIN_TENANT: {
    name: 'ADMIN_TENANT',
    component: AdminTenantLayout,
  },
};
