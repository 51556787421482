<template>
  <aside id="offcanvas-sidemenu" class="app-sidebar -translate-x-full xl:translate-x-0" aria-label="Sidebar">
    <div id="logo-cont" class="app-sidebar-logo-container">
      <RouterLink :to="ROUTER.ADMIN.HOME_PAGE.PATH">
        <img src="../../assets/branch-logo.svg" class="app-sidebar-logo" alt="Digital Hands Logo" />
      </RouterLink>
    </div>
    <div id="menu-cont" class="app-sidebar-menu-container flex flex-col gap-4">
      <RouterLink :to="ROUTER.ADMIN.HOME_PAGE.PATH" class="button-text text-left !text-text !gap-1">
        <CaretLeftIcon class="size-4" />
        <span>Back to Admin Homepage</span>
      </RouterLink>
      <SideMenu side-menu-id="offcanvas-sidemenu" :items="menuItems" @on-item-click="handleItemClick" />
    </div>
  </aside>
</template>

<script setup lang="ts">
import { computed, markRaw, onMounted, ref, watch } from 'vue';
import { RouterLink, useRouter } from 'vue-router';
import { initFlowbite } from 'flowbite';

import HomeIcon from '@/components/icons/HomeIcon.vue';
import FileIcon from '@/components/icons/FileIcon.vue';

import { ROUTER } from '../../app-constants/router.constant';
import { SideMenuItem } from '@/entities/sideMenuItem.entity';
import SideMenu from './SideMenu.vue';
import FileChartIcon from '../icons/FileChartIcon.vue';
import FileShieldIcon from '../icons/FileShieldIcon.vue';
import CaretLeftIcon from '../icons/CaretLeftIcon.vue';

const router = useRouter();
const { params: routerParams } = router.currentRoute.value;

const buildMenuItems = (tenantId: string): SideMenuItem[] => {
  return [
    {
      id: ROUTER.ADMIN_TENANT.HOME_PAGE.KEY,
      name: ROUTER.ADMIN_TENANT.HOME_PAGE.NAME,
      link: ROUTER.ADMIN_TENANT.HOME_PAGE.PATH.replace(':tenantId', tenantId),
      icon: markRaw(HomeIcon),
    },
    {
      id: ROUTER.ADMIN_TENANT.LOGS_PAGE.KEY,
      name: ROUTER.ADMIN_TENANT.LOGS_PAGE.NAME,
      link: ROUTER.ADMIN_TENANT.LOGS_PAGE.PATH.replace(':tenantId', tenantId),
      icon: markRaw(FileChartIcon),
    },
    {
      id: ROUTER.ADMIN_TENANT.FILES_PAGE.KEY,
      name: ROUTER.ADMIN_TENANT.FILES_PAGE.NAME,
      link: ROUTER.ADMIN_TENANT.FILES_PAGE.PATH.replace(':tenantId', tenantId),
      icon: markRaw(FileIcon),
    },
    {
      id: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.KEY,
      name: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.NAME,
      link: ROUTER.ADMIN_TENANT.SECURITY_POSTURE_PAGE.PATH.replace(':tenantId', tenantId),
      icon: markRaw(FileShieldIcon),
    },
  ];
};

const currentTenantId = computed(() => routerParams.tenantId as string);
const menuItems = ref<SideMenuItem[]>(buildMenuItems(currentTenantId.value));

const handleItemClick = (item: SideMenuItem): void => {
  const itemFromList = menuItems.value.find((menuItem) => menuItem.id === item.id);
  itemFromList?.children?.length && (itemFromList.expanded = !itemFromList.expanded);
};

watch(
  () => router.currentRoute.value,
  () => {
    const newTenantId = router.currentRoute.value.params.tenantId as string;
    menuItems.value = buildMenuItems(newTenantId);
  },
);

onMounted(() => {
  initFlowbite();
});
</script>
