<script>
export default {
  name: 'SignupLayout',
};
</script>
<script setup lang="ts"></script>

<template>
  <main class="main relative h-full">
    <div class="h-full p-4 xl:pt-5">
      <div class="login-container">
        <div class="login-box">
          <div class="login-box-logo">
            <img alt="Digital Hands" src="/assets/DHLogo.png" />
          </div>
          <div class="login-box-content">
            <RouterView />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped>
.main {
  font-size: var(--text-size);
  color: var(--fg);
  background: linear-gradient(180deg, rgba(0, 17, 61, 0.9) -7.09%, rgba(10, 15, 28, 0.96) 48.86%, #000000 111.14%);
  display: flex;
  flex-direction: column;
}
.login-container {
  flex-grow: 1;
  display: flex;
  min-height: 100%;
}
.login-container:deep(*) {
  @apply font-sans text-sm;
}
.login-container:deep(a) {
  @apply text-primary-400;
}
.login-container:deep(a:hover) {
  @apply underline;
}

.login-box {
  margin: auto;
  width: 90vw;
  max-width: 500px;
}
@media (min-width: 768px) {
  .login-box {
    width: 50vw;
  }
}
@media (min-width: 1280px) {
  .login-box {
    width: 40vw;
  }
}

.login-box-logo {
  @apply flex flex-col items-center justify-center;
  margin-bottom: 1.6rem;
}
.login-box-content {
  @apply p-8;
  @apply bg-container;
  @apply rounded-lg;
  @apply flex flex-col gap-5;
}
</style>
