<script setup lang="ts">
import AppLayout from '@/components/app-layouts/AppLayout.vue';
import ValidateSessionExpiration from '@/components/ValidateSessionExpiration.vue';
import { Notivue, Notification, NotivueTheme, NotivueKeyboard } from 'notivue';
import { Config } from 'tailwindcss';
import { inject } from 'vue';
import { configNVTheme } from '../notivue.config';
const theme: Config = inject('theme');
const nvTheme: NotivueTheme = configNVTheme(theme);
</script>

<template>
  <ValidateSessionExpiration />
  <NotivueKeyboard v-slot="{ containersTabIndex }">
    <Notivue v-slot="item" :class="{ CenterOnMobile: true }" :containers-tab-index="containersTabIndex">
      <Notification :item="item" :theme="nvTheme" />
    </Notivue>
  </NotivueKeyboard>
  <AppLayout />
</template>
