<template>
  <button
    type="button"
    class="app-navbar-user-profile-button"
    aria-expanded="false"
    data-dropdown-toggle="dropdown-user"
  >
    <img
      class="app-navbar-user-profile-icon app-navbar-user-profile-logo rounded-full"
      :src="avatarUrl"
      :alt="userFullName"
    />
    <div class="hidden xl:flex xl:flex-col xl:pl-2">
      <div class="text-base text-left font-medium dark:text-white">{{ userFullName }}</div>
      <div class="text-base text-left font-light truncate dark:text-text-support">
        {{ userRoleName }}
      </div>
    </div>
    <ChevronDownIcon class="ml-4 w-4 h-4 hidden xl:block" :viewBox="`4 4 16 16`" />
  </button>
  <div id="dropdown-user" class="z-20 hidden px-2 pt-3">
    <div class="w-56 text-base list-none rounded divide-y shadow dark:bg-container-low-emphasis dark:divide-gray-400">
      <div class="px-4 py-3">
        <p class="text-xs font-public font-medium text-text-support">{{ tenantName }}</p>
        <p class="text-base font-inter font-semibold text-text">{{ userFullName }}</p>
        <p class="text-xs font-public font-medium text-text-support">{{ userRoleName }}</p>
      </div>
      <ul class="py-1 text-text-support" aria-labelledby="dropdown">
        <li class="w-full h-[37px]">
          <a
            class="block text-sm leading-[21px] font-normal py-2 px-4 dark:text-red-500 dark:hover:bg-gray-500"
            href="javascript:void(0);"
            @click="handleLogout"
            >Sign out</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useImpersonationInfo } from '@/hooks/useImpersonationInfo';
import { getUserFullName, getUserFirstRole, getLetterAvatarImage } from '@/utils/user.utils';
import ChevronDownIcon from '../icons/ChevronDownIcon.vue';

const impersonationInfo = useImpersonationInfo();
const impersonatedUser = impersonationInfo.impersonatedUser;
const impersonatedTenant = impersonationInfo.impersonatedTenant;

const userFullName = getUserFullName(impersonatedUser) ?? '';
const firstUserRole = getUserFirstRole(impersonatedUser);
const userRoleName = firstUserRole?.roleName ?? '';
const avatarUrl = getLetterAvatarImage(impersonatedUser);
const tenantName = impersonatedTenant?.tenantName ?? '';

const handleLogout = (): void => {
  impersonationInfo.signout();
};
</script>
